/* Live */
import cookie from "react-cookies";
export const appId = "01DF0942-0B19-4E95-8EBA-2BEFFD2EFFE9";

export const apiUrl = "https://marketplace.goepicware.com/api/";
export const apiUrlV2 = "https://marketplace.goepicware.com/apiv2/";
export const apiUrlPro = "https://marketplace.goepicware.com/ninjapro/";
export const apiUrlNotify = "https://ccpl.promobuddy.asia/Pushorder/";
export const siteMainURL = "https://marketplace.goepicware.com/";

export const baseUrl = "http://localhost:3000/";
//export const baseUrl = "https://hq.goepicware.com/";

export const stripeImage = "/img/stripe_logo.png";

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId = "";
export const reservationId = "";
export const dineinId = "";

export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "Goepicware";

export const fbAppId = "684030098921355";

export const stripeKey = "pk_test_mSKQhHZYbRvm1YvuX9mKN4zx";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "goepicware";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "SGD";
export const ConfigoutletCategory = { 1: "Cafe", 2: "Pantry" };
export const defaultpantryOutlet = "321";
export const adminlimit = "10";

var accesstoken = {
  Authorization: cookie.load("accessToken"),
};

export const masterheaderconfig = {
  headers: accesstoken,
};

var clientaccesstoken = {
  Authorization: cookie.load("clientAccessToken"),
};

export const clientheaderconfig = {
  headers: clientaccesstoken,
};

export const awsCredentials = {
  accessKeyId: "AKIATICWS2EXCXAHYWVQ",
  secretAccessKey: "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",
  region: "us-east-1",
  signatureVersion: "v4",
};
export const bucketName = "goepicmarketplacemedia";
export const foldername = "abcpvt";
