/* eslint-disable */
import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

import cart from "../../common/images/cart.svg";
import list from "../../common/images/list.svg";
import wishlist from "../../common/images/wishlist.svg";
import mpin from "../../common/images/map-pin-green.svg";
import ypin from "../../common/images/map-pin-yellow.svg";
import gold from "../../common/images/point-gold.svg";
import barrow from "../../common/images/back-arrow.svg";
import banner from "../../common/images/banner.jpeg";
import banner1 from "../../common/images/banner1.jpeg";
import vicon from "../../common/images/voucher-icon.svg";
import hicon from "../../common/images/history-icon.svg";
import searchg from "../../common/images/search-green.svg";
import kasa1 from "../../common/images/kasa.png";
import food from "../../common/images/food.png";
import chn from "../../common/images/Chicken.png";
import bur from "../../common/images/Burger.png";
import cke from "../../common/images/Cake.png";
import ndle from "../../common/images/Noddle.png";
import piz from "../../common/images/Pizza.png";
import cha from "../../common/images/cha-time.png";
import auntt from "../../common/images/aunt.png";
import sbks from "../../common/images/starbuks.png";
import heartline from "../../common/images/heart.svg";
import gbike from "../../common/images/green-bike.svg";
import gspoon from "../../common/images/green-spoon.svg";
import gchat from "../../common/images/chat-icon.svg";
import tele from "../../common/images/telephone-icon.svg";
import sory from "../../common/images/sorry.svg";
import notyet from "../../common/images/not-yet.svg";
import sampleimg from "../../common/images/sample.png";
import plusi from "../../common/images/plus.svg";
import minusi from "../../common/images/minus.svg";

import gsend from "../../common/images/gosend.svg";
import grab from "../../common/images/grabexpress.svg";
import ra from "../../common/images/rara.svg";
import mr from "../../common/images/mrspeedy.svg";
import lala from "../../common/images/lalamove.svg";

import ccicon from "../../common/images/credit-card-icnn.svg";
import gopy from "../../common/images/gopay.png";
import shoppay from "../../common/images/shopeepay.svg";
import one from "../../common/images/oneklik.png";
import bc from "../../common/images/bca.svg";

import { go } from "react-router-redux";

var mbanner = {
  items: 1,
  loop: true,
  dots: true,
  nav: false,
  margin: 15,
  stagePadding: 40,
  responsive: {
    0: {
      items: 1,
      stagePadding: 20,
    },
    480: {
      items: 1,
      margin: 15,
      stagePadding: 40,
    },
  },
};

var foodbanner = {
  items: 4,
  loop: true,
  dots: false,
  nav: false,
  margin: 13,
  stagePadding: 30,
};

var recmdbanner = {
  items: 2,
  loop: true,
  dots: false,
  nav: false,
  margin: 13,
  stagePadding: 40,
  responsive: {
    0: {
      items: 1,
      stagePadding: 40,
    },
    380: {
      items: 2,
      stagePadding: 30,
    },
  },
};

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = { openFilterSheet: false };
  }
  openfilter() {
    this.setState({ openFilterSheet: true });
  }

  render() {
    return (
      <div className="main-div">
        <div className="header-action">
          <div className="container">
            <div className="ha-lhs">
              <a href="#" className="arrow-back">
                <img src={barrow} />
              </a>
              <span>Your Location</span>
              <p>
                <img src={mpin} /> Tebet, Jakarta
              </p>
            </div>
            <div className="ha-rhs">
              <ul>
                <li>
                  <a href="#">
                    <img src={list} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={wishlist} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={cart} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            <div className="member-card">
              <div className="member-card-txt">
                <p>Member Card</p>
                <h3>1 Member Point(s)</h3>
              </div>
              <div className="member-card-points">
                <img src={gold} />
                <div className="ultra-p">
                  <p>Ultra Point</p>
                  <h2>250</h2>
                </div>
              </div>
            </div>

            <div className="banner">
              <OwlCarousel options={mbanner}>
                <div>
                  <a href="#">
                    <img src={banner} />{" "}
                  </a>
                </div>
                <div>
                  <a href="#">
                    <img src={banner1} />{" "}
                  </a>
                </div>
              </OwlCarousel>
            </div>
            <div className="useful-links">
              <a href="#" className="mv-link">
                <img src={vicon} alt="voucher" /> My Voucher <span>3</span>
              </a>
              <a href="#" className="hp-link">
                <img src={hicon} alt="History" /> History Point
              </a>
            </div>
            <div className="on-going-home">
              <h2>Ongoing Order (2)</h2>
              <div className="og-box mb10">
                <div className="ogbox-img">
                  <img src={gbike} alt="Bike" />
                </div>
                <div className="ogbox-txt">
                  <span>Driver On The Way</span>
                  <h3>KFC - Kota Kasablanka</h3>
                  <p>4 Items Est. delivered (14.30 - 15.00)</p>
                  <div className="ogbox-btn">
                    <a
                      href={void 0}
                      onClick={this.openfilter.bind(this)}
                      className="button btn-ghost"
                    >
                      <img src={tele} alt="Telephone" /> Call Driver
                    </a>
                    <a href="#" className="button btn-normal">
                      <img src={gchat} alt="Chat" /> Chat Driver
                    </a>
                  </div>
                </div>
              </div>
              <div className="og-box">
                <div className="ogbox-img">
                  <img src={gspoon} alt="Bike" />
                </div>
                <div className="ogbox-txt">
                  <span>Preparing Order</span>
                  <h3>Chatime - Jatiwaringin</h3>
                  <p>2 Items Preparing Within 25 mins</p>
                </div>
              </div>
            </div>
            <div className="main-search">
              <div className="ms-inner">
                <img src={searchg} alt="voucher" />
                <input
                  type="text"
                  className="input-fill"
                  placeholder="Find your food"
                />
              </div>
            </div>
            <div className="eats-area">
              <div className="ea-lhs">
                <span>Eats Area</span>
                <p>
                  <img src={ypin} /> Mall Kota Kasablanka
                </p>
              </div>
              <div className="ea-rhs">
                <img src={kasa1} />
              </div>
            </div>
            <div className="pp-tags">
              <ul>
                <li className="active">
                  {" "}
                  <a href="#">Near me</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#">Popular</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#">Best Seller</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#">Promo</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#">Near me</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#">Popular</a>{" "}
                </li>
              </ul>
            </div>
            <div className="outlet-listing">
              <div className="outlet-listing-header">
                <h4>Near Me</h4>
                <a href="#">See more</a>
              </div>
              <div className="outlet-main-list">
                <ul>
                  <li>
                    <a href="#" className="ot-parent">
                      <div className="ot-img">
                        <img src={food} alt="Outlet" />
                      </div>
                      <div className="ot-info">
                        <h3 className="ot-title">KFC - Kota Kasablanka</h3>
                        <div className="km-rating">
                          <strong>0.3 km</strong>
                          <span>4.8 (1,298)</span>
                        </div>
                        <div className="op-time">Open 09.00 - 17.00 WIB</div>
                        <div className="ot-keyword">
                          Food, Chicken, Burger, Nasi, Bento
                        </div>
                        <div className="ot-offers">
                          Save 20% promo, Free Shipping, etc
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ot-parent">
                      <div className="ot-img">
                        <img src={food} alt="Outlet" />
                      </div>
                      <div className="ot-info">
                        <h3 className="ot-title">
                          Street Boba - Kota Kasablanka
                        </h3>
                        <div className="km-rating">
                          <strong>0.3 km</strong>
                          <span>4.8 (1,298)</span>
                        </div>
                        <div className="op-time">Open 09.00 - 17.00 WIB</div>
                        <div className="ot-keyword">Beverage</div>
                        <div className="ot-offers">
                          Extra 50% Birthday Gift.
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ot-parent">
                      <div className="ot-img">
                        <img src={food} alt="Outlet" />
                      </div>
                      <div className="ot-info">
                        <h3 className="ot-title">KFC - Kota Kasablanka</h3>
                        <div className="km-rating">
                          <strong>0.3 km</strong>
                          <span>4.8 (1,298)</span>
                        </div>
                        <div className="op-time">Open 09.00 - 17.00 WIB</div>
                        <div className="ot-keyword">
                          Food, Chicken, Burger, Nasi, Bento
                        </div>
                        <div className="ot-offers">
                          Save 20% promo, Free Shipping, etc
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ot-parent">
                      <div className="ot-img">
                        <img src={food} alt="Outlet" />
                      </div>
                      <div className="ot-info">
                        <h3 className="ot-title">
                          Street Boba - Kota Kasablanka
                        </h3>
                        <div className="km-rating">
                          <strong>0.3 km</strong>
                          <span>4.8 (1,298)</span>
                        </div>
                        <div className="op-time">Open 09.00 - 17.00 WIB</div>
                        <div className="ot-keyword">Beverage</div>
                        <div className="ot-offers">
                          Extra 50% Birthday Gift.
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ot-parent">
                      <div className="ot-img">
                        <img src={food} alt="Outlet" />
                      </div>
                      <div className="ot-info">
                        <h3 className="ot-title">KFC - Kota Kasablanka</h3>
                        <div className="km-rating">
                          <strong>0.3 km</strong>
                          <span>4.8 (1,298)</span>
                        </div>
                        <div className="op-time">Open 09.00 - 17.00 WIB</div>
                        <div className="ot-keyword">
                          Food, Chicken, Burger, Nasi, Bento
                        </div>
                        <div className="ot-offers">
                          Save 20% promo, Free Shipping, etc
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ot-parent">
                      <div className="ot-img">
                        <img src={food} alt="Outlet" />
                      </div>
                      <div className="ot-info">
                        <h3 className="ot-title">
                          Street Boba - Kota Kasablanka
                        </h3>
                        <div className="km-rating">
                          <strong>0.3 km</strong>
                          <span>4.8 (1,298)</span>
                        </div>
                        <div className="op-time">Open 09.00 - 17.00 WIB</div>
                        <div className="ot-keyword">Beverage</div>
                        <div className="ot-offers">
                          Extra 50% Birthday Gift.
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="che-slider">
              <h2>Choose your Eats!</h2>
              <OwlCarousel options={foodbanner}>
                <div>
                  <a href="#">
                    <img src={chn} /> <p>Chicken</p>
                  </a>
                </div>
                <div>
                  <a href="#">
                    <img src={bur} /> <p>Burger</p>
                  </a>
                </div>
                <div>
                  <a href="#">
                    <img src={cke} /> <p>Cake</p>
                  </a>
                </div>
                <div>
                  <a href="#">
                    <img src={piz} /> <p>Pizza</p>
                  </a>
                </div>
                <div>
                  <a href="#">
                    <img src={ndle} /> <p>Noddle</p>
                  </a>
                </div>
              </OwlCarousel>
            </div>

            <div className="recommand-slider">
              <h2>Recommendation</h2>
              <OwlCarousel options={recmdbanner}>
                <div className="re-parent">
                  <a href="#" className="like">
                    {" "}
                    <img src={heartline} />{" "}
                  </a>
                  <a href="#" className="main-link">
                    <div className="re-mainimg">
                      <img src={cha} />
                      <div className="discount-badge">
                        <div className="discount-badge-inner">
                          <span>UP TO</span>
                          <div className="dark-red">
                            <strong>
                              20<sup>%</sup>
                            </strong>
                            <p>Off</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="re-main-desc">
                      <h4>
                        Chatime
                        <br /> TEBET
                      </h4>
                      <div className="km-rating">
                        <strong>0.3 km</strong>
                        <span>4.8 (1,298)</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="re-parent">
                  <a href="#" className="like">
                    {" "}
                    <img src={heartline} />{" "}
                  </a>
                  <a href="#" className="main-link">
                    <div className="re-mainimg">
                      <img src={cha} />
                    </div>
                    <div className="re-main-desc">
                      <h4>
                        Chatime <br />
                        TEBET
                      </h4>
                      <div className="km-rating">
                        <strong>0.3 km</strong>
                        <span>4.8 (1,298)</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="re-parent">
                  <a href="#" className="like">
                    {" "}
                    <img src={heartline} />{" "}
                  </a>
                  <a href="#" className="main-link">
                    <div className="re-mainimg">
                      <img src={auntt} />
                    </div>
                    <div className="re-main-desc">
                      <h4>
                        Auntie Anne’s <br />
                        TEBET
                      </h4>
                      <div className="km-rating">
                        <strong>0.3 km</strong>
                        <span>4.8 (1,298)</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="re-parent">
                  <a href="#" className="like">
                    {" "}
                    <img src={heartline} />{" "}
                  </a>
                  <a href="#" className="main-link">
                    <div className="re-mainimg">
                      <img src={sbks} />
                    </div>
                    <div className="re-main-desc">
                      <h4>
                        Starbuck <br />
                        TEBET
                      </h4>
                      <div className="km-rating">
                        <strong>0.3 km</strong>
                        <span>4.8 (1,298)</span>
                      </div>
                    </div>
                  </a>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
        <BottomSheet
          open={this.state.openFilterSheet}
          className="bottomSheetMain nagetive-popup choose-vehicle do-uwant"
          onDismiss={() => this.setState({ openFilterSheet: false })}
          footer={
            <a href="#" className="button button-full">
              Confirm
            </a>
          }
        >
          {/*  Cancel Order */}
          {/* <div className="do-uwant-popup">
            <p>Do you want to cancel this order?</p>
            <div className="do-ubtn">
              <a href="#" className="button light-danger">
                No
              </a>
              <a href="#" className="button dark-danger">
                Yes
              </a>
            </div>
          </div> */}

          <div className="textcenter nagetive-parent">
            <div className="sry-no">
              <img src={sory} />
              <h2>
                There are issues with the <br />
                delivery orders
              </h2>
              <p>
                Sorry for the inconvenience. Try to check again later or order
                from another restaurant
              </p>
              <a href="#" className="button button-full">
                Got it
              </a>
            </div>
          </div>

          {/*    <div className="textcenter nagetive-parent">
            <div className="sry-no service-no">
              <img src={notyet} />
              <h2>Service Not Available</h2>
              <p>
                Sorry the delivery address exceeds maximum radius for delivery.
                Please change to nearest address or select another merchant
              </p>
              <a href="#" className="button button-full">
                Change Location
              </a>
            </div>
          </div>*/}

          <div className="edit-custm-order">
            <div className="edit-product-name">
              <div className="ep-lhs">
                <h2>Value Serbu</h2>
              </div>
              <div className="ep-rhs">
                <span>
                  <sup>Rp</sup> 150.000
                </span>
                <em>Basic Price</em>
              </div>
            </div>
            <div className="ed-pro-main">
              <div className="ed-pro-main-top">
                <div className="product-img">
                  <img src={sampleimg} alt="Eats Image" />
                </div>
                <div className="product-desc">
                  <div className="ed-pro-merge">
                    <h3 className="ot-title">KFC - Kota Kasablanka</h3>
                    <p>5 Pcs Chicken + 3 Rice + 3 Mocha Float</p>
                  </div>
                  <div className="ed-pro-price">
                    <sup>Rp</sup>156.500
                  </div>
                </div>
              </div>
              <div className="ed-pro-main-btm">
                <div className="ep-lhs">
                  <a href="#">Edit Menu</a>
                </div>
                <div className="ep-rhs">
                  <div className="qty-bx">
                    <span className="qty-minus">
                      <img src={minusi} />
                    </span>
                    <input type="text" placeholder="0" />
                    <span className="qty-plus">
                      <img src={plusi} />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="ed-pro-main">
              <div className="ed-pro-main-top">
                <div className="product-img">
                  <img src={sampleimg} alt="Eats Image" />
                </div>
                <div className="product-desc">
                  <div className="ed-pro-merge">
                    <h3 className="ot-title">KFC - Kota Kasablanka</h3>
                    <p>5 Pcs Chicken + 3 Rice + 3 Mocha Float</p>
                  </div>
                  <div className="ed-pro-price">
                    <sup>Rp</sup>156.500
                  </div>
                </div>
              </div>
              <div className="ed-pro-main-btm">
                <div className="ep-lhs">
                  <a href="#">Edit Menu</a>
                </div>
                <div className="ep-rhs">
                  <div className="qty-bx">
                    <span className="qty-minus">
                      <img src={minusi} />
                    </span>
                    <input type="text" placeholder="0" />
                    <span className="qty-plus">
                      <img src={plusi} />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <a href="#" className="button add-anr-btn height-declared">
              Add Another Custom Order
            </a>
          </div>

          {/*  <div className="vehilce-type">
            <div className="vt-header textcenter">
              <h2>Vehicle Type</h2>
            </div>
            <div className="vehicle-list">
              <ul>
                <li>
                  <div className="accordion-head">
                    <h3>Bike</h3>
                    <p>Ideal for small to medium items | up to 5 kg</p>
                  </div>
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <figure>
                          <img src={gsend} />
                        </figure>
                        <div className="traveling-desc">
                          <strong>Gosend</strong>
                          <span>Rp15.000</span>
                        </div>
                      </li>
                      <li>
                        <figure>
                          <img src={grab} />
                        </figure>
                        <div className="traveling-desc">
                          <strong>Grab Express</strong>
                          <span>Rp10.000</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="accordion-head">
                    <h3>Car</h3>
                    <p>Ideal for small to large items | up to 10 kg</p>
                  </div>
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <figure>
                          <img src={ra} />
                        </figure>
                        <div className="traveling-desc">
                          <strong>Rara Delivery</strong>
                          <span>Rp81.000</span>
                        </div>
                      </li>
                      <li className="active">
                        <figure>
                          <img src={mr} />
                        </figure>
                        <div className="traveling-desc">
                          <strong>Mr Speedy</strong>
                          <span>Rp88.000</span>
                        </div>
                      </li>
                      <li>
                        <figure>
                          <img src={lala} />
                        </figure>
                        <div className="traveling-desc">
                          <strong>Lalamove</strong>
                          <span>Rp90.000</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>*/}

          {/*   <div className="paymnt-type">
            <div className="vt-header textcenter">
              <h2>Payment Methods</h2>
            </div>
            <div className="paymnt-list">
              <ul>
                <li>
                  <div className="pt-cover">
                    <figure>
                      <img src={ccicon} />
                    </figure>
                    <h3>Debit/Credit Card</h3>
                    <p>Admin Fee Rp. 1.500</p>
                  </div>
                </li>
                <li>
                  <div className="pt-cover">
                    <figure>
                      <img src={bc} />
                    </figure>
                    <h3>BCA Virtual Account</h3>
                    <p>Admin Fee Rp. 1.500</p>
                  </div>
                </li>
                <li>
                  <div className="pt-cover">
                    <figure>
                      <img src={one} />
                    </figure>
                    <h3>Debit/Credit Card</h3>
                    <p>Admin Fee Rp. 1.500</p>
                  </div>
                </li>
                <li>
                  <div className="pt-cover">
                    <figure>
                      <img src={gopy} />
                    </figure>
                    <h3>Debit/Credit Card</h3>
                    <p>Admin Fee Rp. 1.500</p>
                  </div>
                </li>
                <li>
                  <div className="pt-cover">
                    <figure>
                      <img src={shoppay} />
                    </figure>
                    <h3>Debit/Credit Card</h3>
                    <p>Admin Fee Rp. 1.500</p>
                  </div>
                </li>
              </ul>
            </div>
          </div> */}
        </BottomSheet>
      </div>
    );
  }
}

export default Home;
