import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { apiUrl } from '../../components/Settings/Config';
import { formatRupiah } from '../../components/Helpers/SettingHelper';
import Loader from '../../components/Loader/index';
import '../../tailwind.css';
import { useHistory } from 'react-router';
import { ArrowLeftIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { ClockIcon } from '@heroicons/react/24/outline';
import { CopyIcon, WaitingPaymentIcon } from '../../utils/svg';
import { formatDate } from '../../utils/helper';
import useToast from '../../components/Toast/index';

const Payment = () => {
	const location = useLocation();
	const history = useHistory();
	const session_token = location.state?.session_token || null;
	const orderDate = location.state?.orderDate || null;
	const paymentState = location.state?.data || null;

	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingCheckPayment, setIsLoadingCheckPayment] = useState(false);
	const [paymentData, setPaymentData] = useState(null);
	const [timeLeft, setTimeLeft] = useState('');

	const [openIndex, setOpenIndex] = useState(null);

	const { showToast } = useToast();

	const toggleOpen = (index) => {
		setOpenIndex(openIndex === index ? null : index);
	};

	useEffect(() => {
		if (!paymentState) return;
		setIsLoading(true);
		setPaymentData(paymentState);
		setIsLoading(false);
	}, [paymentState]);

	useEffect(() => {
		const expirationDateJakarta = formatDate(paymentData?.expired_at, 'YYYY-MM-DD HH:mm:ss');
    console.log('expirationDateJakarta: ', expirationDateJakarta);

		const calculateTimeLeft = () => {
			const now = new Date();
      console.log('now: ', now);
      const expirationDateObj = new Date(expirationDateJakarta);
			const difference = expirationDateObj - now;
      console.log('difference: ', difference);

			if (difference > 0) {
				const hours = String(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
				const minutes = String(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
				const seconds = String(Math.floor((difference % (1000 * 60)) / 1000)).padStart(2, '0');

				setTimeLeft(`${hours} : ${minutes} : ${seconds}`);
			} else {
				setTimeLeft('Expired');
			}
		};

		calculateTimeLeft();
		const timer = setInterval(calculateTimeLeft, 1000);
		return () => clearInterval(timer);
	}, [paymentData?.expired_at]);

	const handleGetPaymentStatus = async () => {
		try {
			setIsLoadingCheckPayment(true);
			const data = new FormData();
			data.append('session_token', session_token);
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			const url = `${apiUrl}status-payment`;
			const res = await axios.post(url, data, config);
			if (res?.data?.status === 'ok') {
				const result = res?.data?.data || {};
				if (result?.status === 'SUCCESS') {
					history.push('/');
				}
				setPaymentData((prev) => {
					return {
						...prev,
						payment_status: result?.status,
					};
				});
			}
			setIsLoadingCheckPayment(false);
		} catch (error) {
			console.log('error: ', error);
			setIsLoadingCheckPayment(false);
		}
	};

	useEffect(() => {
		if (!session_token) return;
		handleGetPaymentStatus();
	}, [session_token]);

	const copyToClipboard = (text) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				console.log('copy', text);
				showToast('info', 'coppied');
			})
			.catch((err) => {
				console.error('Failed to copy: ', err);
			});
	};

	return (
		<>
			{isLoading ? (
				<>
					<Loader />
				</>
			) : (
				<>
					{isLoadingCheckPayment && <Loader overlay />}
					<div className='h-screen w-full flex justify-center items-center bg-white'>
						<div className='relative h-screen w-full max-w-lg'>
							<div className={`absolute z-10 mt-5 w-full`}>
								<div className='flex flex-row justify-between mx-4'>
									<div className='flex justify-center items-center cursor-pointer' onClick={() => history.push('/summary')}>
										<ArrowLeftIcon className='h-8 w-8 text-gray-900 p-1.5' />
										<div className='pl-2 text-base text-gray-900 font-semibold truncate max-w-72'>Payment</div>
									</div>
								</div>
								<div className='border-b-1 border-solid pb-2 border-gray-200' />
								<div className={`mx-4 mt-4`}>
									<div className='text-sm text-gray-900 font-semibold'>Detail Transaction</div>
									<div className={`flex justify-between items-center mt-4`}>
										<div>
											<div className='text-xs text-gray-500 font-thin flex justify-start items-center'>Order Number</div>
											<div className='text-xs text-gray-500 font-thin mt-2 flex justify-start items-center'>Purchased On</div>
											<div className='text-xs text-gray-500 font-thin mt-2 flex justify-start items-center'>Status</div>
										</div>
										<div>
											<div className='flex items-center justify-end gap-2'>
												<div className='text-xs text-gray-900 font-semibold'>{paymentData?.order_id}</div>
												<div
													onClick={() => {
														console.log('click copy order number');
														copyToClipboard(paymentData?.order_id);
													}}>
													<CopyIcon />
												</div>
											</div>
											<div className='text-xs text-gray-900 font-semibold mt-2 flex justify-end items-center'>{formatDate(orderDate, 'D MMMM YYYY, h:mm A')}</div>
											<div className='text-xs text-star font-normal mt-2 flex justify-end items-center'>{paymentData?.payment_status}</div>
										</div>
									</div>
								</div>
								<div className='mx-4 mt-8 mb-28'>
									<div className='mt-4 border-solid border-1 border-gray-300 rounded-lg px-4 py-2.5'>
										<div className={`flex justify-between`}>
											<div className={`flex`}>
												<WaitingPaymentIcon />
												<div className='pl-2'>
													<div className='text-gray-900 text-sm font-semibold'>Complete payment in</div>
													<div className='text-gray-500 text-xs font-normal pt-1'>{formatDate(orderDate, 'dddd, MMMM D, YYYY HH:mm')}</div>
												</div>
											</div>
											<div className='bg-red-100 flex h-5 w-24 items-center p-2 rounded-full'>
												<ClockIcon className='h-4 w-4 text-red-500' />
												<div className='text-xs font-semibold pl-1 text-red-500'>{timeLeft}</div>
											</div>
										</div>
										<div className='border-b-1 border-solid pb-2 border-gray-300 mt-4' />
										<div className={`flex justify-between items-center mt-4`}>
											<div className='flex flex-col'>
												<div className='text-gray-500 text-xs font-normal'>Virtual Account Number</div>
												<div className='flex items-center pt-1'>
													<div className='text-gray-800 text-base font-bold'>{paymentData?.payment_generated}</div>
													<div
														className='pl-1'
														onClick={() => {
															console.log('copy va number');
															copyToClipboard(paymentData?.payment_generated);
														}}>
														<CopyIcon />
													</div>
												</div>
											</div>
											<div className='h-4 w-auto'>
												<img
													alt={paymentData?.payment_category?.payment_method?.[0]?.code ?? ''}
													src={paymentData?.payment_category?.payment_method?.[0]?.image_url}
													className='h-full w-auto object-contain object-center'
												/>
											</div>
										</div>
										<div className={`flex flex-col mt-8`}>
											<div className='text-gray-900 text-xs font-normal'>Total Payment</div>
											<div className='flex items-center pt-1'>
												<div className='text-gray-800 text-base font-bold'>{formatRupiah(Number(paymentData?.total_amount) || 0)}</div>
												<div
													className='pl-1'
													onClick={() => {
														console.log('copy total amount');
														copyToClipboard(String(paymentData?.total_amount));
													}}>
													<CopyIcon />
												</div>
											</div>
										</div>
										<div className='border-b-1 border-solid pb-2 border-gray-300' />
										<div className='flex justify-between items-center mt-8 gap-2'>
											<button
												className={`bg-white text-rose-violet w-full font-semibold text-sm p-3 items-center border-1 border-solid border-rose-violet`}
												onClick={() => history.push('/')}>
												Back to home
											</button>
											<button
												disabled={paymentData?.payment_status === 'EXPIRED'}
												className={`${
													paymentData?.payment_status === 'EXPIRED' ? 'bg-gray-300 text-white border-gray-300' : 'bg-rose-violet text-white border-rose-violet'
												} w-full font-semibold text-sm p-3 items-center border-1 border-solid`}
												onClick={() => {
													console.log('click already paid');
													handleGetPaymentStatus();
												}}>
												Already Paid
											</button>
										</div>
									</div>
									<div className='mt-8'>
										<div className='text-gray-800 text-base font-bold'>How to make a payment:</div>
										<div className='ml-4'>
											{paymentData?.payment_instructions?.map((instruction, index) => {
												const isOpen = openIndex === index;
												return (
													<div key={`instruction_${index}`}>
														<div className='flex justify-between items-center py-2 cursor-pointer' onClick={() => toggleOpen(index)}>
															<div className='text-gray-800 text-sm font-bold'>{instruction?.title ?? ''}</div>
															{isOpen ? <ChevronUpIcon className='h-4 w-4 text-gray-900' /> : <ChevronDownIcon className='h-4 w-4 text-gray-900' />}
														</div>
														{isOpen && (
															<div className='ml-2'>
																{instruction?.description.split('\n').map((line, idx) => (
																	<p key={idx} className='text-gray-900 text-xs mb-1'>
																		{line}
																	</p>
																))}
															</div>
														)}
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Payment;
