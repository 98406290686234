import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import cart from "../../common/images/cart.svg";
import list from "../../common/images/list.svg";
import wishlist from "../../common/images/wishlist.svg";

import barrow from "../../common/images/back-arrow.svg";
import atop from "../../common/images/arrow-up.svg";
import adown from "../../common/images/arrow-down.svg";
import food from "../../common/images/food.png";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="main-div">
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <a href="#" className="arrow-back">
                <img src={barrow} />
              </a>
            </div>
            <div className="ha-middle">Membership</div>
            <div className="ha-rhs">
              <ul>
                <li>
                  <a href="#">
                    <img src={wishlist} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={cart} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            <div className="member-points-head">
              <div className="mph-lhs">
                <img src={food} alt="Outlet" />
              </div>
              <div className="mph-rhs">
                <h2>MAP Group</h2>
                <p>
                  50.000 <span>point(s)</span>
                </p>
              </div>
            </div>
            <div className="member-points-list">
              <ul>
                <li className="a-top">
                  <img src={atop} alt="Outlet" />
                  <div className="mpl-text">
                    <h4>Lorem ipsum Lorem</h4>
                    <p>10:35 WIB</p>
                  </div>
                  <div className="mpl-point">
                    <span>+100.000</span>
                    <em>Ultra Value</em>
                  </div>
                </li>
                <li className="a-down">
                  <img src={adown} alt="Outlet" />
                  <div className="mpl-text">
                    <h4>Lorem ipsum Lorem</h4>
                    <p>10:35 WIB</p>
                  </div>
                  <div className="mpl-point">
                    <span>-60.000</span>
                    <em>Point</em>
                  </div>
                </li>
                <li className="a-top">
                  <img src={atop} alt="Outlet" />
                  <div className="mpl-text">
                    <h4>Lorem ipsum Lorem</h4>
                    <p>10:35 WIB</p>
                  </div>
                  <div className="mpl-point">
                    <span>+100.000</span>
                    <em>Ultra Value</em>
                  </div>
                </li>
                <li className="a-down">
                  <img src={adown} alt="Outlet" />
                  <div className="mpl-text">
                    <h4>Lorem ipsum Lorem</h4>
                    <p>10:35 WIB</p>
                  </div>
                  <div className="mpl-point">
                    <span>-60.000</span>
                    <em>Point</em>
                  </div>
                </li>
                <li className="a-top">
                  <img src={atop} alt="Outlet" />
                  <div className="mpl-text">
                    <h4>Lorem ipsum Lorem</h4>
                    <p>10:35 WIB</p>
                  </div>
                  <div className="mpl-point">
                    <span>+100.000</span>
                    <em>Ultra Value</em>
                  </div>
                </li>
                <li className="a-down">
                  <img src={adown} alt="Outlet" />
                  <div className="mpl-text">
                    <h4>Lorem ipsum Lorem</h4>
                    <p>10:35 WIB</p>
                  </div>
                  <div className="mpl-point">
                    <span>-60.000</span>
                    <em>Point</em>
                  </div>
                </li>
                <li className="a-top">
                  <img src={atop} alt="Outlet" />
                  <div className="mpl-text">
                    <h4>Lorem ipsum Lorem</h4>
                    <p>10:35 WIB</p>
                  </div>
                  <div className="mpl-point">
                    <span>+100.000</span>
                    <em>Ultra Value</em>
                  </div>
                </li>
                <li className="a-down">
                  <img src={adown} alt="Outlet" />
                  <div className="mpl-text">
                    <h4>Lorem ipsum Lorem</h4>
                    <p>10:35 WIB</p>
                  </div>
                  <div className="mpl-point">
                    <span>-60.000</span>
                    <em>Point</em>
                  </div>
                </li>
                <li className="a-top">
                  <img src={atop} alt="Outlet" />
                  <div className="mpl-text">
                    <h4>Lorem ipsum Lorem</h4>
                    <p>10:35 WIB</p>
                  </div>
                  <div className="mpl-point">
                    <span>+100.000</span>
                    <em>Ultra Value</em>
                  </div>
                </li>
                <li className="a-down">
                  <img src={adown} alt="Outlet" />
                  <div className="mpl-text">
                    <h4>Lorem ipsum Lorem</h4>
                    <p>10:35 WIB</p>
                  </div>
                  <div className="mpl-point">
                    <span>-60.000</span>
                    <em>Point</em>
                  </div>
                </li>
                <li className="a-top">
                  <img src={atop} alt="Outlet" />
                  <div className="mpl-text">
                    <h4>Lorem ipsum Lorem</h4>
                    <p>10:35 WIB</p>
                  </div>
                  <div className="mpl-point">
                    <span>+100.000</span>
                    <em>Ultra Value</em>
                  </div>
                </li>
                <li className="a-down">
                  <img src={adown} alt="Outlet" />
                  <div className="mpl-text">
                    <h4>Lorem ipsum Lorem</h4>
                    <p>10:35 WIB</p>
                  </div>
                  <div className="mpl-point">
                    <span>-60.000</span>
                    <em>Point</em>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
