import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiUrl, deliveryId, unquieID } from '../../components/Settings/Config';
import { encodeValue, formatRupiah, getCookie, isEmptyData, setCookie } from '../../components/Helpers/SettingHelper';
import Loader from '../../components/Loader/index';
import '../../tailwind.css';
import { useHistory } from 'react-router';
import { MagnifyingGlassIcon, ArrowLeftIcon, StarIcon } from '@heroicons/react/24/solid';
import { HeartIcon } from '@heroicons/react/24/outline';
import { BikeIcon, DiscountIcon, TagIcon, WishtlingIcon } from '../../utils/svg';
import Footer from '../../components/Footer';
import qs from 'qs';
import ProductDetail from '../../components/Product';
import ProductCart from '../../components/Product/cart';

const Outlet = () => {
	const history = useHistory();
	const { slug } = useParams();

	const customerID = getCookie('customerID');
	const latitude = getCookie('latitude');
	const longitude = getCookie('longitude');
	const [isLoading, setIsLoading] = useState(true);
	const [store, setStore] = useState(null);
	const [products, setProducts] = useState([]);

	const [isLoadingCart, setisLoadingCart] = useState(false);
	const [cart, setCart] = useState(null);
	const [cartItem, setCartItem] = useState(null);

	const [isChangeStoreCart, setIsChangeStoreCart] = useState(false);

	const handleGetStore = async () => {
		console.log('handleGetStore');
		try {
      // setIsLoading(true);
			let url = `${apiUrl}store/storeDetails?unquieid=${unquieID}&customerID=${customerID}`;
      if (/^\d+$/.test(slug)) {
        url += `&storeID=${slug}`
      } else {
        url += `&storeSlug=${slug}`
      }

			if (latitude && longitude) {
				url += `&latitude=${latitude}&longitude=${longitude}`;
			}
			const res = await axios.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res.data?.status === 'ok') {
				const data = res.data?.result;
				setStore(data);
        setCookie('storeID', data?.storeID);
				setCookie('storeAddress', data?.storeAddress);
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const handleGetProducts = async () => {
		if (isEmptyData(store?.storeID)) return;
		console.log('handleGetProducts');
		try {
			const store_id = btoa(store?.storeID);
			let url = `${apiUrl}catalogs/listproducts?unquieid=${unquieID}&storeID=${store_id}`;

			const res = await axios.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res.data?.status === 'ok') {
				setProducts(res.data?.result);
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const handleCart = async (data) => {
		console.log('handleCart');
		try {
			setisLoadingCart(true);
			let postData = {
				unquieid: unquieID,
				availabilityID: deliveryId,
				availabilityName: 'Delivery',
				shopID: encodeValue(store?.storeID),
				customerID: customerID,
				productType: data?.productType,
				productID: data?.id,
				quantity: data?.total,
				type: data?.type,
				specialNotes: data?.note || '',
				itemID: data?.itemID,
			};
			if (data?.productType === 'Simple') {
				postData = {
					...postData,
				};
			} else {
				postData = {
					...postData,
					productTotalPrice: data?.totalPrice,
					productPrice: data?.price,
					comboset: JSON.stringify(data?.comboSet),
				};
			}
			console.log('postData: ', postData);
			const url = `${apiUrl}cart/${data?.type === 'add' ? 'createCart' : 'updateCartItem'}`;
			const res = await axios.post(url, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				setCartItem(null);
				await handleGetCart();
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	};

	const handleRemoveCart = async () => {
		console.log('handleRemoveCart');
		try {
			const postData = {
				unquieid: unquieID,
				customerID: customerID,
			};
			const res = await axios.post(`${apiUrl}cart/removeCart`, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				setIsChangeStoreCart(false);
				setCart(null);
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const handleGetCart = async () => {
		console.log('handleGetCart');
		try {
			const url = `${apiUrl}cart/loadCartDetails?unquieid=${unquieID}&customerID=${customerID}&availabilityID=${deliveryId}`;
			const res = await axios.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res?.data?.status === 'ok') {
				setCart(res?.data?.result || null);
			}
      if (res?.data?.message === 'There are no items in your cart') {
        setCart(null);
      }
		} catch (error) {
			console.log('error: ', error);
		}
	};

  useEffect(() => {
    setIsLoading(true);
  }, [])
  

	useEffect(() => {
		setIsLoading(true);
		handleGetStore();
		handleGetCart();
	}, []);

	useEffect(() => {
		if (!store) return;
		handleGetProducts();
		setIsLoading(false);
	}, [store]);

	const handleGetProductDetail = async (slug) => {
		console.log('handleGetProductDetail', slug);
		try {
			if (cart?.store?.storeID && cart?.store?.storeID !== store?.storeID) {
				console.log('change cart outlet');
				return setIsChangeStoreCart(true);
			}
			setisLoadingCart(true);
			const url = `${apiUrl}catalogs/productdetails?unquieid=${unquieID}&productSlug=${slug}`;
			const res = await axios.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res.data?.status === 'ok') {
				const product = res.data?.result || null;
				setCartItem(product);
			}
			setisLoadingCart(false);
		} catch (error) {
			setisLoadingCart(false);
		}
	};

	const handleFavorite = async () => {
		console.log('handleFavorite');
		try {
			const dataPost = {
				unquieid: unquieID,
				shopID: encodeValue(store?.storeID),
				customerID: customerID,
			};
			const url = `${apiUrl}store/${Boolean(Number(store?.favorite || store?.favourite)) ? 'removeFavourite' : 'addFavourite'}`;
			const res = await axios.post(url, qs.stringify(dataPost));
			if (res?.data?.status === 'ok') {
				handleGetStore();
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	return (
		<>
			{isLoading ? (
				<>
					<Loader />
				</>
			) : (
				<>
					{isLoadingCart && <Loader overlay />}
					<div className='h-screen w-full flex justify-center items-center'>
						<div className='relative h-screen w-full max-w-lg'>
							<div className='aspect-[2/1] w-full h-full max-h-52 absolute'>
								<img alt={store?.storeSlug} src={store?.bannerImage} className='h-full w-full object-cover' />
							</div>
							<div className={`absolute z-10 mt-5 w-full max-w-lg`}>
								<div className='flex flex-row justify-between mx-4'>
									<div className='flex justify-center items-center cursor-pointer' onClick={() => history.push('/')}>
										<ArrowLeftIcon className='h-8 w-8 text-gray-900 bg-white rounded-full p-1.5' />
									</div>
									<div className='flex gap-2'>
										<div className='flex justify-center items-center cursor-pointer' onClick={() => history.push('/')}>
											<MagnifyingGlassIcon className='h-8 w-8 text-gray-900 bg-white rounded-full p-1.5' />
										</div>
										<div className='flex justify-center items-center cursor-pointer' onClick={handleFavorite}>
											<HeartIcon className={`h-8 w-8 ${Boolean(Number(store?.favorite || store?.favourite)) ? 'fill-red-500 text-red-500' : 'text-gray-900'} bg-white rounded-full p-1.5`} />
										</div>
									</div>
								</div>
								<div className='flex mx-4 bg-white mt-16 rounded-lg p-4 shadow-xl'>
									<img alt={store?.storeSlug} src={store?.storeImage} className='h-12 w-12 rounded-lg resize-none' />
									<div className='pl-3'>
										<div className='text-gray-900 text-sm font-semibold'>{store?.storeName}</div>
										<div className='flex gap-1 items-center pt-1'>
											<StarIcon className='h-4 w-4 text-star' />
											<div className='text-black text-xs'>{store?.rating || 0}</div>
											<div className='text-gray-900 text-xs'>({store?.totalRating})</div>
										</div>
										<div className='flex flex-row gap-1 pt-1'>
											<BikeIcon />
											<div className='flex flex-row'>
												<div className='text-gray-900 text-xs'>{Math.floor(Number(store?.distance || 0) * 10) / 10} km</div>
												<div className='hidden text-xs'>
													<div className='px-1'>-</div>
													<div className='flex flex-row'>
														Delivery <p className='px-1 line-through'>Rp16.000</p> Rp6.000
													</div>
												</div>
											</div>
										</div>
										<div className='hidden gap-1 pt-1'>
											<TagIcon />
											<div className='font-semibold text-xs text-gray-900'>Discount up to 50%</div>
										</div>
									</div>
								</div>
								<div className='mx-4 mt-5 hidden'>
									<div className='text-base text-gray-800 font-semibold'>Favorite’s Items You Maybe Like</div>
									<div className='mt-4 flex overflow-scroll scrollbar-hide'>
										{products?.map((product, index) => {
											return (
												<ProductDetail
													productKey={`${product?.product_id}_${index}`}
													id={product?.product_id}
													slug={product?.product_slug}
													image={product?.product_thumbnail}
													rating={store?.rating || store?.Rating}
													totalRating={store?.totalRating}
													name={product?.product_name}
													price={Number(product?.product_price)}
													strikeOutPrice={Number(product?.product_cost)}
													size='small'
													productType={product?.product_type}
													handleGetProductDetail={handleGetProductDetail}
													cart={cart}
													handleCart={handleCart}
												/>
											);
										})}
									</div>
								</div>
								<div className={`mx-4 mt-5 ${cart && 'mb-24'}`}>
									<div className='text-base text-gray-800 font-semibold hidden'>Feature Items</div>
									<div className='mt-4 columns-2'>
										{products?.map((product, index) => {
											return (
												<ProductDetail
													productKey={`${product?.product_id}_${index}`}
													id={product?.product_id}
													slug={product?.product_slug}
													image={product?.product_thumbnail}
													rating={store?.rating || store?.Rating}
													totalRating={store?.totalRating}
													name={product?.product_name}
													price={Number(product?.product_price)}
													strikeOutPrice={Number(product?.product_cost)}
													productType={product?.product_type}
													handleGetProductDetail={handleGetProductDetail}
													cart={cart}
													handleCart={handleCart}
												/>
											);
										})}
									</div>
								</div>
							</div>
							{cart && (
								<Footer position='fixed' id='cart'>
									<div className={`bg-white p-4 shadow-custom transition-transform duration-1000 ease-in-out ${cart ? 'translate-y-0 relative' : 'translate-y-full hidden'}`}>
										<div className='items-center hidden'>
											<DiscountIcon />
											<div className='pl-2 text-gray-900 text-xs'>Yay, you’re saving 10k. Add more and save up to 50k!</div>
										</div>
										<button
											className='mt-2 w-full bg-byzantium text-white flex justify-between font-normal text-sm p-3 border-0items-center border-0'
											onClick={() => history.push('/summary')}>
											<div>{cart?.totalItem} item in cart</div>
											<div>{formatRupiah(parseInt(cart?.subTotal || 0))}</div>
										</button>
									</div>
								</Footer>
							)}
							{isChangeStoreCart && (
								<Footer position='fixed' id='isChangeStoreCart' onTouchOutside={() => setIsChangeStoreCart(false)}>
									<div
										className={`bg-white p-4 rounded-xl shadow-custom transition-transform duration-1000 ease-in-out ${
											isChangeStoreCart ? 'translate-y-0 relative' : 'translate-y-full hidden'
										}`}>
										<div className='flex justify-center'>
											<div className='bg-gray-300 h-1.5 w-10 rounded-full' />
										</div>
										<div className='items-center flex justify-center mt-8'>
											<WishtlingIcon />
										</div>
										<div className='my-5 text-center'>
											<div className='text-gray-900 text-sm font-semibold'>Want to buy from this restaurant?</div>
											<div className='text-gray-900 text-xs pt-2'>We'll need to clear the items in your current cart.</div>
										</div>
										<div className='flex justify-between mt-2 gap-4'>
											<button
												className='w-full bg-white text-byzantium border-byzantium font-semibold text-sm p-3 items-center border'
												onClick={() => setIsChangeStoreCart(false)}>
												Cancel
											</button>
											<button onClick={handleRemoveCart} className='w-full bg-byzantium text-white font-semibold text-sm p-3 items-center border-0'>
												Yes, Sure
											</button>
										</div>
									</div>
								</Footer>
							)}
							{cartItem && <ProductCart product={cartItem} cart={cart} handleCart={handleCart} setCartItem={setCartItem} />}
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Outlet;
