import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import { apiUrl, unquieID } from "../Settings/Config";
import barrow from "../../common/images/back-arrow.svg";
import Autocomplete from "../Layout/Autocomplete";
import $ from "jquery";
import { setCookie } from "../Helpers/SettingHelper";
class AddressLists extends Component {
  constructor(props) {
    super(props);
    var stateName = "";
    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      stateName =
        this.props.location.state.statename !== "" &&
        typeof this.props.location.state.statename !== undefined &&
        typeof this.props.location.state.statename !== "undefined"
          ? this.props.location.state.statename
          : "";
    }
    this.state = {
      stateName: stateName,
      address: "",
      latitude: "",
      longitude: "",
      addresssList: [],
    };
    this.loadAddressList();
  }

  setAddress = (address) => {
    console.log('setAddress', address);
    setCookie("address", address?.address)
    setCookie("latitude", address?.latitude)
    setCookie("longitude", address?.longitude)
    setCookie("country", address?.country)
    setCookie("state", address?.state)
    setCookie("city", address?.city)
    setCookie("village", address?.village)
    setCookie("district", address?.district)
    setCookie("postalCode", address?.postalCode)
    this.props.history.push("/");
    // if (field === "address") {
      // if (value !== "") {
      //   var address = $("#pac-input").val();
      //   console.log(address, "addressaddress");
      //   var addressdetails = value.split("~~");
      //   this.setState(
      //     {
      //       address: address,
      //       latitude: addressdetails[1],
      //       longitude: addressdetails[2],
      //     },
      //     function () {
      //       cookie.save("deliveryaddress", this.state.address, { path: "/" });
      //       cookie.save("deliverycountry", addressdetails[0], { path: "/" });
      //       cookie.save("deliverystate", addressdetails[5], { path: "/" });
      //       cookie.save("deliverypostalCode", addressdetails[4], {
      //         path: "/",
      //       });
      //       cookie.save("deliverycity", addressdetails[3], { path: "/" });
      //       cookie.save("deliveryaddresslat", this.state.latitude, {
      //         path: "/",
      //       });
      //       cookie.save("deliveryaddresslong", this.state.longitude, {
      //         path: "/",
      //       });
      //       this.props.history.push("/add-address");
      //     }
      //   );
      // }
    // }
  };
  loadAddressList() {
    console.log('loadAddressList');
    axios
      .get(
        apiUrl +
          "address/getAddressList?unquieid=" +
          unquieID +
          "&customerID=" +
          cookie.load("customerID")
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ addresssList: res.data.result });
        }
      });
  }
  selectAddress(address) {
    console.log('selectAddress: ', address);
    setCookie('address', address?.address)
    setCookie('latitude', address?.latitude)
    setCookie('longitude', address?.longitude)
    // console.log('selectAddress', address);
    // cookie.save("deliveryaddress", address.address, { path: "/" });
    // cookie.save("deliverycountry", address.country, { path: "/" });
    // cookie.save("deliverystate", address.state, { path: "/" });
    // cookie.save("deliverypostalCode", address.postal_code, {
    //   path: "/",
    // });
    // cookie.save("deliverycity", address.city, { path: "/" });
    // cookie.save("deliverydistrict", address.district, { path: "/" });
    // cookie.save("deliveryaddresslat", address.latitude, {
    //   path: "/",
    // });
    // cookie.save("deliveryaddresslong", address.longitude, {
    //   path: "/",
    // });
    // cookie.save("addressID", address.address_id, {
    //   path: "/",
    // });
    this.props.history.push("/");
  }
  render() {
    return (
      <div className="main-div">
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <Link to={"/"} className="arrow-back"> 
                <img src={barrow} />
              </Link>
            </div>
            <div className="ha-middle">Add Address</div>
            <div className="ha-rhs"></div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            <div className="location-pin-select">
              {this.state.stateName !== "" && (
                <div className="lo-dp">
                  {/* todo remove */}
                  <Link to={"/delivery-state"}>
                    {this.state.stateName}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </Link>
                </div>
              )}
              <div className="lo-search">
                <Autocomplete
                  setAddress={this.setAddress}
                  placeholder="Enter your perfect destination."
                />
              </div>
            </div>
            {this.state.addresssList.length > 0 && (
              <div className="add-address-list">
                <ul>
                  {this.state.addresssList.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={this.selectAddress.bind(this, item)}
                      >
                        <h3>{item.addressname}</h3>
                        <p>{item.address}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AddressLists;
