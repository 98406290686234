import { useEffect, useRef, useState } from 'react';
import { apiUrl, maxLengthNotes } from '../Settings/Config';
import { getCookie, isEmptyData } from '../Helpers/SettingHelper';
import axios from 'axios';
import '../../tailwind.css';

const Notes = ({ item_id = '', notes = '', callback = () => {} }) => {
	const [note, setNote] = useState('');
	const debounceTimeout = useRef(null);

	useEffect(() => {
		if (!isEmptyData(notes)) {
			setNote(notes);
		}
	}, [notes]);

	const debounce = (func, delay) => {
		return (...args) => {
			if (debounceTimeout.current) {
				clearTimeout(debounceTimeout.current);
			}
			debounceTimeout.current = setTimeout(() => {
				func(...args);
			}, delay);
		};
	};

	const handleUpdateNotes = async (note) => {
		try {
      if (!item_id) {
        return callback(note);
      }
			const data = new FormData();
			data.append('item_id', item_id);
			data.append('notes', note);

			await axios
				.post(`${apiUrl}cart/update-note-cart-item`, data, {
					headers: {
						Authorization: getCookie('clientAccessToken'),
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((res) => {
					console.log('res: ', res);
					callback();
				})
				.catch((err) => {
					console.log('err: ', err);
				});
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const debouncedUpdate = debounce((newNote) => {
		handleUpdateNotes(newNote);
	}, 1000);

	const handleChange = (note) => {
		const newNote = note;
		setNote(newNote);
		debouncedUpdate(newNote);
	};

	return (
		<div className='w-full'>
			<div className='flex items-center'>
				<div className='text-xs font-semibold text-gray-800'>Notes to Restaurant</div>
				<div className='text-xs font-normal pl-2'>Optional</div>
			</div>
			<textarea
				name='notes'
				id='notes'
				maxLength={maxLengthNotes}
				className='px-4 py-2 mt-2 border-1 border-gray-200 text-xs font-normal text-gray-800 border-solid bg-white placeholder:text-xs placeholder:text-gray-400 h-auto'
				value={note || ''}
				rows={1}
        placeholder='Add your request'
				onChange={(e) => handleChange(e.target.value)}></textarea>
		</div>
	);
};

export default Notes;

