import imageCompression from 'browser-image-compression';

/**
 * Compresses an image file using specified options.
 * @param {File} file - The image file to compress.
 * @returns {Promise<File>} - The compressed image file.
 */
export const compressImage = async (file) => {
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error('Error compressing image:', error);
    return file;
  }
};

/**
 * Saves an image to IndexedDB.
 * @param {string} key - The database name.
 * @param {string} imageUrl - The image URL (used as a key in the store).
 * @param {string} dataUrl - The data URL of the image.
 */
export const saveToIndexedDB = (key, imageUrl, dataUrl) => {
  const dbName = key;
  const request = indexedDB.open(dbName, 1);

  request.onupgradeneeded = (event) => {
    const db = event.target.result;
    db.createObjectStore('images', { keyPath: 'url' });
  };

  request.onsuccess = (event) => {
    const db = event.target.result;
    const transaction = db.transaction(['images'], 'readwrite');
    const store = transaction.objectStore('images');
    store.put({ url: imageUrl, data: dataUrl });

    transaction.oncomplete = () => {
      console.log('Image saved to IndexedDB');
    };
  };

  request.onerror = (event) => {
    console.error('Error opening IndexedDB:', event.target.error);
  };
};

/**
 * Retrieves an image from IndexedDB.
 * @param {string} key - The database name.
 * @param {string} url - The image URL used as a key in the store.
 * @returns {Promise<string|null>} - The data URL of the image or null if not found.
 */
export const getFromIndexedDB = (key, url) => {
  return new Promise((resolve, reject) => {
    const dbName = key;
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore('images', { keyPath: 'url' });
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['images'], 'readonly');
      const store = transaction.objectStore('images');
      const getRequest = store.get(url);

      getRequest.onsuccess = () => {
        const result = getRequest.result;
        resolve(result ? result.data : null);
      };

      getRequest.onerror = (event) => {
        reject(event.target.error);
      };
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

/**
 * Displays an image from IndexedDB.
 * @param {string} key - The database name.
 * @param {string} imageUrl - The image URL used as a key in the store.
 * @returns {Promise<string|null>} - The data URL of the image or null if not found.
 */
export const displayImageFromIndexedDB = async (key, imageUrl) => {
  try {
    const dataUrl = await getFromIndexedDB(key, imageUrl);
    return dataUrl || null;
  } catch (error) {
    console.error('Error fetching image from IndexedDB:', error);
    return null;
  }
};

/**
 * Fetches, compresses, and stores the image data URL in IndexedDB.
 * @param {string} key - The database name.
 * @param {string} product_thumbnail - The product thumbnail URL.
 * @param {string} imageUrl - The image URL to fetch.
 * @returns {Promise<string|null>} - The data URL of the image or null if not found.
 */
export const fetchAndCompressImage = async (key, product_thumbnail, imageUrl) => {
  let dataUrl = await getFromIndexedDB(key, product_thumbnail);

  if (!dataUrl) {
    try {
      const response = await fetch(imageUrl, {
        method: 'GET',
        redirect: 'follow',
      });

      if (response.ok) {
        const blob = await response.blob();
        // const compressedFile = await compressImage(blob);
        const reader = new FileReader();
        reader.onloadend = () => {
          dataUrl = reader.result;
          saveToIndexedDB(key, product_thumbnail, dataUrl);
        };
        reader.readAsDataURL(blob);
      } else {
        console.error('Error fetching image:', response.statusText);
      }
    } catch (error) {
      console.error('Error in fetchAndCompressImage:', error);
    }
  }

  return dataUrl;
};