import React, { useEffect, useMemo, useState } from 'react';
import { formatRupiah, removeHTML } from '../../components/Helpers/SettingHelper';
import '../../tailwind.css';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/solid';
import FoodImg from '../../common/images/food.png';
import Footer from '../../components/Footer';
import Notes from '../../components/Notes/index';

const ProductCart = ({ product = {}, cart = null, handleCart = (data) => {}, setCartItem = (data) => {} }) => {
	const currentCartItem = cart?.item?.find((item) => item.productID === product?.product_id) || null;

	const [imageLoadStatus, setImageLoadStatus] = useState('loaded');
	const desc = removeHTML(product?.short_description || '');
	const [total, setTotal] = useState(1);
	const [note, setNote] = useState('');
	const [comboSet, setComboSet] = useState([]);

	const totalPrice = useMemo(() => {
		const comboPrice = comboSet?.reduce((sum, comb) => {
			const detailPriceSum = comb?.productDetails?.reduce((detailSum, detail) => {
				return detailSum + Number(detail?.productPrice || 0);
			}, 0);
			return sum + detailPriceSum;
		}, 0);

		const productPrice = Number(product?.product_price);
		return (productPrice + comboPrice) * total;
	}, [comboSet, total]);

	const handleClick = () => {
		console.log('ProductCart handleClick');
		const dataCart = {
			id: product?.product_id,
			total: total,
			type: !currentCartItem ? 'add' : 'update',
			productType: product?.product_type === '1' ? 'Simple' : 'Combo',
			note: note,
			comboSet: comboSet,
			price: Number(product?.product_price),
			totalPrice: totalPrice,
      itemID: currentCartItem?.itemID ?? ''
		};
		handleCart(dataCart);
	};

	useEffect(() => {
		if (!currentCartItem) return;
		setTotal(Number(currentCartItem?.itemQuantity || 1));
		setNote(currentCartItem?.itemNotes || '');

    const updatedComboSet = currentCartItem?.comboset?.map((combo) => ({
      comboSetId: combo?.comboSetId,
      comboSetname: combo?.comboSetname,
      productDetails: combo?.productDetails.map((detail) => ({
        productID: detail?.productID,
        productName: detail?.productName,
        productSKU: detail?.productSKU,
        productPrice: Number(detail?.productPrice || 0),
        quantity: 1,
      })),
    })) || [];
    setComboSet(updatedComboSet);

	}, [currentCartItem]);

	const handleComboChange = (comboIndex, comboProduct, comboSetProduct) => {
		console.log('handleComboChange');
		const productDetails = {
			productID: comboSetProduct?.product_id,
			productName: comboSetProduct?.product_name,
			productSKU: comboSetProduct?.product_sku,
			productPrice: Number(comboSetProduct?.product_price || 0),
			quantity: 1,
		};

		setComboSet((prev) => {
			const updatedComboSet = [...prev];
			updatedComboSet[comboIndex] = {
				comboSetId: comboProduct?.combo_id,
				comboSetname: comboProduct?.combo_name,
				productDetails: [productDetails],
				min_max_flag: comboProduct?.max_select || 1,
			};
			return updatedComboSet;
		});
	};

	return (
		<>
			<Footer position='fixed' id='productCart' onTouchOutside={() => setCartItem(null)}>
				<div className={`bg-white rounded-t-xl p-4 shadow-custom transition-transform duration-500 ease-in-out ${true ? 'translate-y-0 relative' : 'translate-y-full hidden'}`}>
					<div className='flex justify-center'>
						<div className='bg-gray-300 h-1.5 w-10 rounded-full' />
					</div>
					<div className='relative mt-4'>
						{imageLoadStatus === 'loaded' ? (
							<img
								alt={product?.product_slug}
								src={product?.product_thumbnail}
								onLoad={() => {
									setImageLoadStatus('loaded');
								}}
								onError={() => {
									setImageLoadStatus('error');
								}}
								className='h-44 w-full rounded-xl object-cover object-center'
							/>
						) : (
							<img alt={product?.product_slug} src={FoodImg} className='h-44 w-full rounded-xl object-cover object-center' />
						)}
					</div>
					<div className='mt-4 text-gray-900 font-semibold text-sm'>{product?.product_name || ''}</div>
					<div className='text-sm flex items-center pt-2'>
						<div className='font-semibold pr-1 text-gray-800'>{formatRupiah(product?.product_price)}</div>
						{Boolean(Number(product?.product_cost)) && <div className='line-through text-gray-500'>{formatRupiah(Number(product?.product_cost))}</div>}
					</div>
					<div className='text-gray-400 text-xs pt-2 font-normal'>{desc}</div>
					<div className='mt-5'>
						{product?.comboset?.map((combo, index) => {
							return (
								<div key={index}>
									<div className='text-sm text-gray-900 font-semibold mb-4'>{combo?.combo_name}</div>
									{combo?.products?.map((comboProduct, idx) => {
										const comboPrice = Number(comboProduct?.product_price) || 0;
                    
                    const isSelected = comboSet?.some((selectedCombo) => {
                      return selectedCombo?.productDetails?.some((detail) => {
                        const isMatching = detail.productID === comboProduct?.product_id;
                        return isMatching;
                      });
                    }) ?? false;

										return (
											<label key={`${index}_${idx}`} htmlFor={`${index}_${idx}`} className='flex items-center justify-between h-12 border-b-1 border-gray-200 border-solid'>
												<div htmlFor={`${index}_${idx}`} className='text-xs text-gray-900 font-semibold flex'>
													{comboProduct?.product_name} {Boolean(comboPrice) && <div className='text-gray-500 text-xs font-normal pl-2'>{`+${formatRupiah(comboPrice)}`}</div>}
												</div>
												<input
													id={`${index}_${idx}`}
													type='radio'
													value={comboProduct?.product_id}
													name={`combo_${index}`}
													className='w-4 h-4 bg-white !border-byzantium after:!bg-byzantium'
													onChange={() => handleComboChange(index, combo, comboProduct)}
													checked={isSelected}
												/>
											</label>
										);
									})}
								</div>
							);
						})}
					</div>
					<div className='mt-5'>
						<Notes item_id={currentCartItem?.itemID ?? ''} callback={setNote} notes={note} />
					</div>
				</div>
				<div className='bg-white w-full p-4 z-auto shadow-custom'>
					<div className='flex justify-between items-center'>
						<div className='text-gray-900 text-xs font-semibold'>Item quantity</div>
						<div className='bg-white p-1 rounded-md cursor-pointer flex items-center border-1 border-solid border-byzantium text-xs'>
							<MinusIcon
								className='h-5 w-5 text-byzantium'
								onClick={() =>
									setTotal((prev) => {
										if (prev === 0) return 0;
										return prev - 1;
									})
								}
							/>
							<input
								type='text'
								className='!bg-white !m-0 !p-0 !h-5 !w-8 text-center text-black font-semibold text-xs'
								value={total}
								onChange={(e) => {
									const value = e.target.value;
									const num = value.replace(/[^0-9]/g, '');
									setTotal(num);
								}}
							/>
							<PlusIcon className='h-5 w-5 text-byzantium' onClick={() => setTotal((prev) => prev + 1)} />
						</div>
					</div>
					<button
						onClick={handleClick}
						disabled={!total}
						className={`mt-2 w-full disabled:cursor-not-allowed  ${!total ? 'bg-gray-400' : 'bg-byzantium'} text-white flex justify-center font-normal text-xs p-3 items-center border-0`}>
						{!currentCartItem ? 'Add to Cart' : 'Update Cart'}
					</button>
				</div>
			</Footer>
		</>
	);
};

export default ProductCart;
