import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiUrl, deliveryId, unquieID } from '../../components/Settings/Config';
import { encodeValue, formatRupiah, getCookie, isEmptyData, setCookie } from '../../components/Helpers/SettingHelper';
import Loader from '../../components/Loader/index';
import '../../tailwind.css';
import { useHistory } from 'react-router';
import { ArrowLeftIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import { HeartIcon, ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { DiscountIcon, DoubleNoteIcon, LoaderIcon, MotorbikeIcon, TicketDiscountIcon, UltraVoucherIcon } from '../../utils/svg';
import Footer from '../../components/Footer';
import qs from 'qs';
import ProductCart from '../../components/Product/cart';
import CartDetail from '../../components/Cart';
import Voucher from '../../components/Voucher';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useToast from '../../components/Toast';

const Summary = () => {
	const history = useHistory();
	const { slug } = useParams();

	const { showToast } = useToast();

	const customerID = getCookie('customerID');
	const address = getCookie('address');
	const firstName = getCookie('firstName');
	const lastName = getCookie('lastName');
	const email = getCookie('email');
	const phone = getCookie('primary_phone');
	const latitude = getCookie('latitude');
	const longitude = getCookie('longitude');
	const village = getCookie('village');
	const district = getCookie('district');
	const city = getCookie('city');
	const state = getCookie('state');
	const country = getCookie('country');
	const postalCode = getCookie('postalCode');
	const notes = getCookie('notes');

	const token = getCookie('token');
	const refresh_token = getCookie('refresh_token');

	const [isLoading, setIsLoading] = useState(true);
	const [store, setStore] = useState();

	const [isLoadingCart, setisLoadingCart] = useState(false);
	const [cart, setCart] = useState(null);
	const [cartItem, setCartItem] = useState(null);

	const [isLoadingDeliveryList, setisLoadingDeliveryList] = useState(true);
	const [isShowDelivery, setIsShowDelivery] = useState(false);
	const [pathnersList, setPathnersList] = useState([]);
	const [delivery, setDelivery] = useState({});

	const [isLoadingVoucherList, setisLoadingVoucherList] = useState(true);
	const [isShowVoucher, setIsShowVoucher] = useState(false);
	const [voucherList, setVoucherList] = useState({
		tabs: ['All', 50000, 100000, 150000],
		active: 'All',
		myTotal: [],
		my: [],
		b2b: [],
		more: false,
	});

	const [isShowPaymentMethod, setIsShowPaymentMethod] = useState(false);
	const [paymentMethodList, setPaymentMethodList] = useState([
		// {
		// 	id: 2,
		// 	category: 'Payment Method',
		// 	image_url: '',
		// 	payment_method: [
		// 		{
		// 			code: 'ULTRA-VOUCHER',
		// 			name: 'Ultra Value',
		// 			minimum_amount: 1,
		// 			maximum_amount: 10000000,
		// 			status: 'ACTIVE',
		// 			fee: 0,
		// 			image_url: 'https://uvdev.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2019-12-23T03%3A16%3A35.175Z1577070994-group.jpeg',
		// 		},
		// 	],
		// },
		{
			id: 4,
			category: 'Other Payment Method',
			image_url: '',
			payment_method: [
				{
					code: 'VABNI',
					name: 'Virtual Account BNI',
					minimum_amount: 10000,
					maximum_amount: 100000000,
					status: 'ACTIVE',
					fee: 0,
					image_url: 'https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2022-09-02T02%3A40%3A55.520Z1662086455-BNI.png',
				},
				{
					code: 'VADIRECTBCA',
					name: 'Virtual Account Direct BCA',
					minimum_amount: 15000,
					maximum_amount: 9000000,
					status: 'ACTIVE',
					fee: 0,
					image_url: 'https://uvdev.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2019-09-30T03%3A39%3A05.606Z1569814745-group.jpeg',
				},
				{
					code: 'VAMANDIRI',
					name: 'Virtual Account Mandiri',
					minimum_amount: 10000,
					maximum_amount: 100000000,
					status: 'ACTIVE',
					fee: 0,
					image_url: 'https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2023-07-11T11%3A21%3A41.987Z1689074501-group.png',
				},
			],
		},
	]);

  const [isLoadingUvBalance, setIsLoadingUvBalance] = useState(true);
	const [UVBalance, setUVBalance] = useState(null);

	const [orderData, setOrderData] = useState({
		unquieid: unquieID,
		customerID: customerID,
		paymentReference: null,
		sender: {
			firstName: firstName,
			email: email,
			phone: phone,
		},
		deliveryType: null,
		deliveryFee: 0,
		deliverySubsidies: 0,
		notes: notes ?? '',
		paymentType: {
			code: 'VABNI',
			name: 'Virtual Account BNI',
			minimum_amount: 10000,
			maximum_amount: 100000000,
			status: 'ACTIVE',
			fee: 0,
			image_url: 'https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2022-09-02T02%3A40%3A55.520Z1662086455-BNI.png',
		},
		origin: null,
		destinations: {
			address: address,
			coordinate: {
				latitude: latitude,
				longitude: longitude,
			},
			village: village,
			district: district,
			city: city,
			province: state,
			postalCode: postalCode,
			state: state,
			country: country,
			recipient: {
				firstName: firstName,
				email: email,
				phone: phone,
				lastName: lastName,
			},
			items: [],
		},
	});

	const orderSchema = Yup.object().shape({
		unquieid: Yup.string().required('Unique ID is required'),
		customerID: Yup.string().required('Customer ID is required'),

		sender: Yup.object().shape({
			firstName: Yup.string().required('First Name is required'),
			email: Yup.string().email('Invalid email format').required('Email is required'),
			phone: Yup.string().required('Phone number is required'),
		}),

		deliveryType: Yup.mixed()
			.test('is-delivery-valid', 'Delivery Type is required', (delivery) => {
				if (typeof delivery === 'object') {
					return true;
				}
				return false;
			})
			.required('Delivery Type is required'),

		notes: Yup.string().optional(),

		paymentType: Yup.mixed()
			.test('is-payment-type-valid', 'Payment Type is required', (payment) => {
				if (typeof payment === 'object' && payment?.code === 'ULTRA-VOUCHER') {
					return Number(UVBalance?.value || 0) > Number(paymentSummary?.totalPayment || 0);
				}
				if (typeof payment === 'object') {
					return true;
				}
				return false;
			})
			.required('Payment Type is required'),

		origin: Yup.object().shape({
			address: Yup.string().required('Origin address is required'),
			keywords: Yup.string().optional(),
			coordinate: Yup.object().shape({
				latitude: Yup.string().required('Origin latitude is required'),
				longitude: Yup.string().required('Origin longitude is required'),
			}),
			village: Yup.string().required('Origin village is required'),
			district: Yup.string().required('Origin district is required'),
			city: Yup.string().required('Origin city is required'),
			province: Yup.string().required('Origin province is required'),
			postalCode: Yup.string().required('Origin postal code is required'),
		}),

		destinations: Yup.object().shape({
			address: Yup.string().required('Destination address is required'),
			coordinate: Yup.object().shape({
				latitude: Yup.string().required('Destination latitude is required'),
				longitude: Yup.string().required('Destination longitude is required'),
			}),
			village: Yup.string().required('Destination village is required'),
			district: Yup.string().required('Destination district is required'),
			city: Yup.string().required('Destination city is required'),
			province: Yup.string().required('Destination province is required'),
			postalCode: Yup.string(),
			recipient: Yup.object().shape({
				firstName: Yup.string().required('Recipient First Name is required'),
				email: Yup.string().email('Invalid email format').required('Recipient email is required'),
				phone: Yup.string().required('Recipient phone number is required'),
			}),
			items: Yup.array().min(1, 'At least one item is required').required('Items are required'),
		}),
	});

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: orderData,
		validationSchema: orderSchema,
		onSubmit: async (values, { resetForm }) => {
			await handlePayment(values, resetForm);
		},
	});

	const { errors, touched, handleSubmit, values, setValues, isSubmitting } = formik;

	useEffect(() => {
    if (!isSubmitting) return;
		if (Object.keys(errors).length > 0 && Object.keys(touched).length > 0) {
			for (const key in errors) {
				if (touched[key]) {
					showToast('error', errors[key]);
				}
			}
		}
	}, [isSubmitting]);

	const handleGetStore = async () => {
		console.log('handleGetStore');
		try {
			let url = `${apiUrl}store/storeDetails?unquieid=${unquieID}&storeID=${cart?.store?.storeID}&customerID=${customerID}`;

			if (latitude && longitude) {
				url += `&latitude=${latitude}&longitude=${longitude}`;
			}
			const res = await axios.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res.data?.status === 'ok') {
				const data = res.data?.result;
				setStore(data);
				setCookie('storeID', data?.storeID);
				setCookie('storeAddress', data?.storeAddress);
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const handleCart = async (data) => {
		console.log('handleCart', data);
		try {
			setisLoadingCart(true);
			let postData = {
				unquieid: unquieID,
				availabilityID: deliveryId,
				availabilityName: 'Delivery',
				shopID: encodeValue(cart?.store?.storeID),
				customerID: customerID,
				productType: data?.productType,
				productID: data?.id,
				quantity: data?.total,
				type: data?.type,
				specialNotes: data?.note || '',
				itemID: data?.itemID,
			};
			if (data?.productType === 'Simple') {
				postData = {
					...postData,
				};
			} else {
				postData = {
					...postData,
					productTotalPrice: data?.totalPrice,
					productPrice: data?.price,
					comboset: JSON.stringify(data?.comboSet),
				};
			}
			console.log('postData: ', postData);
			const url = `${apiUrl}cart/${data?.type === 'add' ? 'createCart' : 'updateCartItem'}`;
			const res = await axios.post(url, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				setCartItem(null);
				await handleGetCart();
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	};

	const handleGetCart = async () => {
		console.log('handleGetCart');
		try {
			// setIsLoading(true);
			const url = `${apiUrl}cart/loadCartDetails?unquieid=${unquieID}&customerID=${customerID}&availabilityID=${deliveryId}`;
			const res = await axios.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res?.data?.status === 'ok') {
				setCart(res?.data?.result || null);
			}
			if (res?.data?.message === 'There are no items in your cart') {
				setCart(null);
        showToast('warning', res?.data?.message)
			}
			setIsLoading(false);
		} catch (error) {
			console.log('error: ', error);
			setIsLoading(false);
		}
	};

	const handleGetPathnerList = async () => {
		console.log('handleGetPathnerList');
		try {
			// setisLoadingCart(true);
			setisLoadingDeliveryList(true);

			const config = {
				headers: {
					'Content-Type': 'application/json',
				},
			};

			const dataPost = {
				unquieid: values?.unquieid,
				customerID: values?.customerID,
				sender: values?.sender,
				origin: values?.origin,
				destinations: values.destinations,
			};

			const url = `${apiUrl}deliverypartners/loadPartnersList`;
			const res = await axios.post(url, JSON.stringify(dataPost), config);

			if (res?.data?.status === 'ok') {
				const { delivery_fee, delivery_subsidies } = res?.data || {};
				setValues((prev) => {
					return {
						...prev,
						deliveryFee: Number(delivery_fee) || 0,
						deliverySubsidies: Number(delivery_subsidies) || 0,
					};
				});
				const result = res?.data?.result || [];
				const normalizedVehicleData = result?.map((item) => {
					item.vehicle = item.vehicle.map((vehicle) => {
						if (vehicle.vehicleType === 'MOTORCYCLE') {
							vehicle.vehicleType = 'bike';
						}
						if (vehicle.vehicleType === 'TRUCK175') {
							vehicle.vehicleType = 'Truck';
						}
						return vehicle;
					});
					return item;
				});
				const serviceList = normalizedVehicleData
					?.map((item) => item.vehicle)
					.flat()
					.reduce((acc, vehicle) => {
						const existingService = acc.find((service) => service.name === vehicle.name && service.serviceType === vehicle.serviceType);

						if (existingService) {
							existingService.vehicle.push(vehicle);
						} else {
							acc.push({
								name: vehicle.name,
								serviceType: vehicle.serviceType,
								vehicle: [vehicle],
							});
						}

						return acc;
					}, [])
					.sort((a, b) => {
						if (a.name === 'UFood') return -1;
						if (b.name === 'UFood') return 1;
						return 0;
					});
				setPathnersList(serviceList);
			}
			// setisLoadingCart(false);
			setisLoadingDeliveryList(false);
		} catch (error) {
			console.log('error: ', error);
			// setisLoadingCart(false);
			setisLoadingDeliveryList(false);
		}
	};

	const handleGetPaymentReference = async () => {
		try {
			console.log('handleGetPaymentReference');
			// setisLoadingCart(true);
			const postData = {
				unquieid: unquieID,
				customerID: customerID,
			};

			const url = `${apiUrl}Paymentuvcr/generatePaymentRefrence`;

			const res = await axios.post(url, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				const reference = res?.data?.paymentRefrence || '';
        console.log('reference: ', reference);
				setValues((prev) => {
					return {
						...prev,
						paymentReference: reference,
					};
				});
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	};

	useEffect(() => {
		setIsLoading(true);
		handleGetCart();
		handleGetPaymentReference();
		handleGetUVBalance();
	}, []);

	useEffect(() => {
		if (!cart) return;
		console.log('use effect origin & items');
		handleGetVoucherList(cart);
		handleGetPaymentFee();
    handleGetStore();
		setValues((prev) => {
			return {
				...prev,
				origin: {
					address: cart?.store?.storeAddress || '',
					coordinate: cart?.store?.coordinate || {},
					village: cart?.store?.village || '',
					district: cart?.store?.district || '',
					city: cart?.store?.city || '',
					province: cart?.store?.province || '',
					postalCode: cart?.store?.postalCode || '',
					keywords: cart?.store?.storeName || '',
				},
				destinations: {
					...prev.destinations,
					items: cart?.item || [],
				},
			};
		});
	}, [cart]);

	useEffect(() => {
		if (delivery?.vehicle?.length === 1) {
			console.log('use effect set delivery');
			setValues((prev) => {
				return {
					...prev,
					deliveryType: delivery?.vehicle?.[0] || null,
				};
			});
		}
	}, [delivery]);

	useEffect(() => {
		if (!address) return;
		console.log('use effect set address');
		setValues((prev) => {
			return {
				...prev,
				destinations: {
					...prev.destinations,
					address: address,
					coordinate: {
						latitude: latitude,
						longitude: longitude,
					},
					village: village,
					district: district,
					city: city,
					province: state,
					postalCode: postalCode,
				},
			};
		});
	}, [address]);

	useEffect(() => {
		if (isEmptyData(values.origin?.address) || isEmptyData(values.destinations?.address) || values.destinations?.items?.length === 0) return;
		console.log('use effect get pathner list');
		handleGetPathnerList();
	}, [values.origin?.address, values.destinations?.address, values.destinations?.items]);

	const handleGetProductDetail = async (slug) => {
		console.log('handleGetProductDetail', slug);
		try {
			setisLoadingCart(true);
			const url = `${apiUrl}catalogs/productdetails?unquieid=${unquieID}&productID=${slug}`;
			const res = await axios.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res.data?.status === 'ok') {
				const product = res.data?.result || null;
				setCartItem(product);
			}
			setisLoadingCart(false);
		} catch (error) {
			setisLoadingCart(false);
		}
	};

	const handleFavorite = async () => {
		console.log('handleFavorite');
		try {
			const dataPost = {
				unquieid: unquieID,
				shopID: encodeValue(cart?.store?.storeID),
				customerID: customerID,
			};
			const url = `${apiUrl}store/${Boolean(Number(store?.favorite || store?.favourite)) ? 'removeFavourite' : 'addFavourite'}`;
			const res = await axios.post(url, qs.stringify(dataPost));
			if (res?.data?.status === 'ok') {
				handleGetStore();
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const handleGetVoucherList = async (cart) => {
		console.log('handleGetVoucherList');
		try {
			// setisLoadingCart(true);
			setisLoadingVoucherList(true);

			let voucherMyLimit = [];
			let voucherMy = [];
			let voucherB2B = [];
			let totalMyVoucher = 0;

			const postDataMy = {
				posID: cart?.store?.posID ?? '',
				token: token,
				refresh_token: refresh_token,
			};
			const myUrl = `${apiUrl}voucher/list`;
			const resMyLimit = await axios.post(myUrl, qs.stringify(postDataMy));
			if (resMyLimit?.data?.status === 'ok') {
				voucherMyLimit = resMyLimit?.data?.data || [];
				voucherMyLimit?.forEach((voucher) => {
					totalMyVoucher += Number(voucher?.total_voucher) || 0;
				});
			}

			if (totalMyVoucher > 0) {
				postDataMy.page = 1;
				postDataMy.limit = totalMyVoucher;
				const resMy = await axios.post(myUrl, qs.stringify(postDataMy));
				if (resMy?.data?.status === 'ok') {
					voucherMy = resMy?.data?.data || [];
				}
			}

			const postDataB2B = {
				merchantCode: cart?.store?.merchantCode ?? '',
				page: 1,
				limit: 10,
			};
			const b2bUrl = `${apiUrl}voucher-b2b/list`;
			const resB2B = await axios.post(b2bUrl, qs.stringify(postDataB2B));
			if (resB2B?.data?.status === 'ok') {
				voucherB2B = resB2B?.data?.data || [];
			}

			setVoucherList((prev) => {
				return {
					...prev,
					myTotal: voucherMyLimit,
					my: voucherMy,
					b2b: voucherB2B,
					more: false,
				};
			});
			// setisLoadingCart(false);
			setisLoadingVoucherList(false);
		} catch (error) {
			console.log('error: ', error);
			// setisLoadingCart(false);
			setisLoadingVoucherList(false);
		}
	};

	const handleUpdateVoucherList = (id, type, use = false) => {
		console.log('handleUpdateVoucherList', id, type);
		setVoucherList((prev) => {
			const updatedList = { ...prev };
			const updateVoucher = (voucherArray) => {
				return voucherArray.map((voucher) => {
					let isUse = voucher.is_use ?? false;
					if (voucher.id === id || voucher.code === id) {
						isUse = !use;
					}
					return {
						...voucher,
						is_use: isUse,
					};
				});
			};

			if (type === 'b2b_voucher') {
				updatedList.b2b = updateVoucher(updatedList.b2b);
			} else if (type === 'my_voucher') {
				updatedList.my = updateVoucher(updatedList.my);
				updatedList.myTotal = updateVoucher(updatedList.myTotal);
			}

			return updatedList;
		});
	};

	const handleGetUVBalance = async () => {
		console.log('handleGetUVBalance');
		try {
			setIsLoadingUvBalance(true);
			const postData = {
				token: token,
				refresh_token: refresh_token,
			};
			const url = `${apiUrl}uv-balance`;
			const res = await axios.post(url, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				const result = res?.data?.data || {};

				setUVBalance({
					code: 'ULTRA-VOUCHER',
					name: result?.name ?? 'Ultra Value',
					value: result?.total_value || 0,
					image: result?.image?.url || '',
				});
			}
			setIsLoadingUvBalance(false);
		} catch (error) {
			console.log('error: ', error);
			setIsLoadingUvBalance(false);
		}
	};

	const myVoucherFiltered = useMemo(() => {
		if (voucherList.active === 'All') {
			return voucherList.my;
		}
		const filtered = voucherList?.my?.filter((voucher) => Number(voucher.nominal_voucher) === Number(voucherList?.active));
		return filtered;
	}, [voucherList]);

	const b2bVoucherFiltered = useMemo(() => {
		if (voucherList.active === 'All') {
			return voucherList.b2b;
		}
		const filtered = voucherList?.b2b?.filter((voucher) => Number(voucher.nominal) === Number(voucherList?.active));
		return filtered;
	}, [voucherList]);

	const totalVoucherFiltered = useMemo(() => {
		if (voucherList.active === 'All') {
			return voucherList.myTotal;
		}
		const filtered = voucherList?.myTotal?.filter((voucher) => Number(voucher.nominal_voucher) === Number(voucherList?.active));
		return filtered;
	}, [voucherList]);

	const extractVoucherData = (voucherList) => {
		const extractMyVouchers = voucherList.my
			.filter((voucher) => voucher.is_use)
			.map((voucher) => ({
				id: voucher.voucher_id,
				name: voucher.name,
				nominal: voucher.nominal_voucher,
				price: voucher.value,
			}));

		const extractB2BVouchers = voucherList.b2b
			.filter((voucher) => voucher.is_use)
			.map((voucher) => ({
				code: voucher.code,
				name: voucher.name,
				nominal: voucher.nominal,
				price: voucher.price,
				merchantCode: voucher?.merchant_code,
				priceUser: voucher.price_user,
			}));

		const totalMyNominal = extractMyVouchers.reduce((sum, voucher) => sum + voucher.nominal, 0);
		const totalB2BNominal = extractB2BVouchers.reduce((sum, voucher) => sum + voucher.nominal, 0);
		const totalMyPrice = extractMyVouchers.reduce((sum, voucher) => sum + voucher.price, 0);
		const totalB2BPrice = extractB2BVouchers.reduce((sum, voucher) => sum + voucher.priceUser, 0);

		const countMyVouchers = extractMyVouchers.length;
		const countB2BVouchers = extractB2BVouchers.length;

		return {
			myVouchers: extractMyVouchers,
			totalMyNominal: totalMyNominal,
			totalMyPrice: totalMyPrice,
			countMyVouchers: countMyVouchers,
			b2bVouchers: extractB2BVouchers,
			totalB2BNominal: totalB2BNominal,
			totalB2BPrice: totalB2BPrice,
			countB2BVouchers: countB2BVouchers,
		};
	};

	const paymentSummary = useMemo(() => {
		const subTotal = Number(cart?.subTotal || 0);
		const packagingFee = Number(cart?.packagingFee || 0);
		const platformFee = Number(cart?.platformFee || 0);
		const deliveryFee = Number(values?.deliveryType?.amount || 0) + Number(values?.deliveryFee);

		const paymentFee = Number(values?.paymentType?.fee || 0);

		const voucherData = extractVoucherData(voucherList);

		const totalNominalVoucher = voucherData.totalMyNominal + voucherData.totalB2BNominal;

		const isDisableVoucher = totalNominalVoucher > subTotal;

		const fixTotalMyVoucherNominal = isDisableVoucher ? subTotal : totalNominalVoucher;

		const totalPayment = subTotal + packagingFee + platformFee + deliveryFee + paymentFee - fixTotalMyVoucherNominal;

		const fixTotalPayment = totalPayment + voucherData.totalB2BPrice;

		return {
			subTotal: subTotal,
			totalPayment: fixTotalPayment,
			deliveryFee: deliveryFee,
			voucherData: voucherData,
			myVoucherApplied: fixTotalMyVoucherNominal,
			countMyVoucherApplied: voucherData.countMyVouchers + voucherData.countB2BVouchers,
			otherFee: 0,
			packagingFee: packagingFee,
			platformFee: platformFee,
			paymentFee: paymentFee,
			isDisableVoucher: isDisableVoucher,
		};
	}, [cart, voucherList, values]);

	const handleGetPaymentFee = useCallback(async () => {
		console.log('handleGetPaymentFee');
		try {
			// setisLoadingCart(true);
			const postData = {
				token: token,
				refresh_token: refresh_token,
				totalAmount: paymentSummary.totalPayment,
			};
			const url = `${apiUrl}calculate-fee`;
			const res = await axios.post(url, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				const result = res?.data?.data || [];

				let currentPaymentTypeFee = 0;

				const updatedPaymentMethodList = paymentMethodList?.map((paymentCategory) => {
					const updatedPaymentMethods = paymentCategory.payment_method.map((method) => {
						const matchedCategory = result.find((item) => item.payment_method.some((pm) => pm.code === method.code));

						if (matchedCategory) {
							const matchedMethod = matchedCategory.payment_method.find((pm) => pm.code === method.code);
							if (matchedMethod.code === values?.paymentType?.code && !currentPaymentTypeFee) {
								currentPaymentTypeFee = matchedMethod?.fee;
							}
							return {
								...method,
								fee: matchedMethod?.fee || method.fee,
							};
						}
						return method;
					});

					return {
						...paymentCategory,
						payment_method: updatedPaymentMethods,
					};
				});

				setPaymentMethodList(updatedPaymentMethodList);
				setValues((prev) => {
					return {
						...prev,
						paymentType: {
							...prev.paymentType,
							fee: currentPaymentTypeFee,
						},
					};
				});
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	}, [paymentSummary]);

	const handlePayment = async (values, resetForm) => {
		console.log('handlePayment');
		try {
			setisLoadingCart(true);

			const myVoucher = paymentSummary?.voucherData?.myVouchers?.map((voucher) => voucher?.id) || [];

			const b2bVoucher =
				paymentSummary?.voucherData?.b2bVouchers?.map((voucher) => {
					return {
						voucher_code: voucher?.code,
						voucher_nominal: voucher?.nominal,
						voucher_price: voucher?.price,
						voucher_merchant_code: voucher?.merchantCode,
						voucher_qty: 1,
						voucher_price_user: voucher?.priceUser,
					};
				}) || [];

			const dataPost = {
				unquieid: values?.unquieid,
				customerID: values?.customerID,
				deliveryCharge: values?.deliveryType?.amount || 0,
				additionalDeliveryCharge: 0,
				taxCharge: 0,
				taxAmount: 0,
				subTotal: paymentSummary?.subTotal,
				grandTotal: paymentSummary?.totalPayment,
				totalDiscount: 0,
				discountType: '',
				totalItem: values?.items?.length || 0,
				instantOrder: 'Yes',
				availabilityID: deliveryId,
				availabilityName: 'Delivery',
				orderSource: 'Web',
				firstName: values?.destinations?.recipient?.firstName || '',
				email: values?.destinations?.recipient?.email || '',
				phone: values?.destinations?.recipient?.phone || '',
				lastName: values?.destinations?.recipient?.lastName || '',
				address: values?.destinations?.address || '',
				village: values?.destinations?.village || '',
				district: values?.destinations?.district || '',
				city: values?.destinations?.city || '',
				state: values?.destinations?.state || '',
				country: values?.destinations?.country || '',
				latitude: values?.destinations?.coordinate?.latitude || '',
				longitude: values?.destinations?.coordinate?.longitude || '',
				billingaddress: cart?.store?.storeAddress,
				storeID: cart?.store?.storeID,
				items: values?.destinations?.items || [],
				deliveryPartner: values?.deliveryType,
				siteURL: 'payment',
				failedURL: 'summary?paymentstatus=failed',
        paymentFee: values?.paymentType.fee || 0,
				paymentMethod: values?.paymentType.code || '',
				paymentGetway: 'SNAP',
				paymentReferenceID: values?.paymentReference || null,
				validateOrder: 'Yes',
				deliveryZone: '',
				packagingFee: paymentSummary?.packagingFee,
				platformFee: paymentSummary?.platformFee,
				deliveryFee: values?.deliveryFee,
				subsidy: values?.deliveryType?.subsidy || false,
				deliverySubsidies: values?.deliverySubsidies,
				token: token,
				refresh_token: refresh_token,
				b2b_voucher: b2bVoucher,
				my_voucher: myVoucher,
			};
      console.log('dataPost: ', dataPost);
			const config = {
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json',
				},
			};

			const urlDelivery = `${apiUrl}deliverypartners/createPartnerOrder`;

			const orderDelivery = await axios.post(urlDelivery, JSON.stringify(dataPost), config);
			if (orderDelivery?.data?.status !== 'ok') {
				console.log(orderDelivery);
        showToast('error', orderDelivery?.data?.message || '', 1000);
				return setisLoadingCart(false);
			}

			dataPost.deliveryOrderID = orderDelivery?.data?.deliveryOrderID;

			const urlPayment = `${apiUrl}paymentuvcr/processPayment`;

			const createPayment = await axios.post(urlPayment, JSON.stringify(dataPost), config);
			if (createPayment?.data?.status !== 'ok') {
				console.log(createPayment);
        showToast('error', createPayment?.data?.message || '', 1000);
				return setisLoadingCart(false);
			}
			setCookie('notes', '');
			setCookie('session_token', createPayment?.data?.session_token || null);
			const propsData = createPayment?.data || {};
			propsData.orderDate = new Date();
			history.push({
				pathname: '/payment',
				state: propsData,
			});
		} catch (error) {
			console.log('error: ', error);
      showToast('error', error?.message ?? '');
			setisLoadingCart(false);
		}
	};
  console.log('paymentReference', values?.paymentReference);
	return (
		<>
			{isLoading ? (
				<>
					<Loader />
				</>
			) : (
				<>
					{isLoadingCart && <Loader overlay />}
					<div className='h-screen w-full flex justify-center items-center bg-white'>
						<div className='relative h-screen w-full max-w-lg'>
							<div className={`absolute z-10 mt-5 w-full`}>
								<div className='flex flex-row justify-between mx-4'>
									<div className='flex justify-center items-center cursor-pointer' onClick={() => isEmptyData(store?.storeSlug) ? history.goBack() : history.push(`/outlet/${store?.storeSlug}`)}>
										<ArrowLeftIcon className='h-8 w-8 text-gray-900 p-1.5' />
										<div className='pl-2 text-base text-gray-900 font-semibold truncate max-w-72'>{cart?.store?.storeName ?? ''}</div>
									</div>
									<div className='flex gap-2'>
										<div className='flex justify-center items-center cursor-pointer' onClick={handleFavorite}>
											<HeartIcon className={`h-8 w-8 ${Boolean(Number(store?.favorite || store?.favourite)) ? 'fill-red-500 text-red-500' : 'text-gray-900'} p-1.5`} />
										</div>
									</div>
								</div>
								<div className='border-b-1 border-solid pb-2 border-gray-200' />
								<div className={`mx-4 mt-4 flex justify-between items-center`}>
									<div>
										<div className='text-xs text-gray-900 font-semibold'>Delivery Location</div>
										<div className='text-sm text-gray-900 font-semibold pt-2 max-w-64'>{values?.destinations?.address}</div>
									</div>
									<button
										className={`${
											errors?.destinations?.address && touched?.destinations?.address ? 'border-red-500 text-red-500' : 'border-rose-violet text-rose-violet'
										} bg-white border-solid border-1 py-2 px-4 text-xs font-semibold rounded-md`}
										onClick={() => history.push('/google-map?back=summary')}>
										Change
									</button>
								</div>
								<div className='mx-4 mt-4'>
									<div className='flex items-center text-center'>
										<DoubleNoteIcon />
										<div className='text-xs text-gray-900 font-semibold pl-2'>Delivery Notes</div>
										<div className='text-xs text-gray-400 font-thin pl-2'>Optional</div>
									</div>
									<textarea
										className='mt-2 py-2.5 px-4 text-xs placeholder:text-xs placeholder:text-gray-400 border-1 border-solid border-gray-300 bg-white h-auto'
										rows={1}
										defaultValue={values?.notes ?? ''}
										onChange={(e) => {
											setValues((prev) => {
												return {
													...prev,
													notes: e.target.value ?? '',
												};
											});
											setCookie('notes', e.target.value);
										}}
										placeholder='e.g. Please leave food at the door'
									/>
								</div>
								<div className='border-b-8 border-solid pb-4 border-gray-100' />
								<div className='mx-4 mt-4'>
									<div className='text-gray-900 text-xs font-semibold'>Delivery Option</div>
									<div className='mt-2'>
										<div className={`border-gray-300 border-1 border-solid rounded-md flex justify-between px-2 py-2.5`}>
											<div className='flex items-center'>
												<MotorbikeIcon />
												<div className='pl-2 text-gray-900 text-sm font-semibold'>{delivery?.name ? `${delivery?.name} ${delivery?.serviceType}` : 'Choose Delivery'}</div>
											</div>
											<button
												className={`${
													errors?.deliveryType && touched?.deliveryType
														? 'border-red-500 text-red-500'
														: isLoadingDeliveryList
														? 'border-gray-300 text-rose-violet'
														: 'border-rose-violet text-rose-violet'
												} bg-white border-solid border-1 py-2 px-4 text-xs font-semibold rounded-md min-w-[80px] flex justify-center items-center`}
												disabled={isLoadingDeliveryList}
												onClick={() => setIsShowDelivery(true)}>
												{isLoadingDeliveryList ? <LoaderIcon /> : delivery?.name ? 'Change' : 'Choose'}
											</button>
										</div>
										{delivery?.vehicle?.map((type, index) => {
											return (
												<div
													key={`type_${index}`}
													className={`border-1 border-gray-300 border-solid rounded-md flex justify-between px-2 py-2.5 mt-2 items-center ${
														type?.vehicleType === values?.deliveryType?.vehicleType ? '' : 'bg-gray-100'
													}`}
													onClick={() => {
														setValues((prev) => {
															return {
																...prev,
																deliveryType: type,
															};
														});
													}}>
													<div className=''>
														<div
															className={`text-sm capitalize ${
																type?.vehicleType === values?.deliveryType?.vehicleType ? 'text-gray-900' : 'text-gray-500'
															} font-semibold`}>
															{type?.vehicleType}
														</div>
														<div className='text-xs text-gray-400 pt-1 font-thin'>20-30 minute . Max 20 kg</div>
													</div>
													<div className='flex'>
														<div className='text-gray-500 font-normal text-sm line-through hidden'>Rp6.000</div>
														<div className={`pl-2 ${type?.vehicleType === values?.deliveryType?.vehicleType ? 'text-gray-900' : 'text-gray-500'} font-semibold text-sm`}>
															{formatRupiah((type?.amount || 0) + values?.deliveryFee)}
														</div>
														<div className='pl-2'>
															<input
																id='transport'
																type='radio'
																name='transport'
																value='bike1'
																checked={type?.vehicleType === values?.deliveryType?.vehicleType}
																onChange={() => null}
																className={`${
																	type?.vehicleType === values?.deliveryType?.vehicleType ? '!border-rose-violet' : '!border-gray-400 !bg-gray-200'
																} after:!bg-rose-violet active:bg-white`}
															/>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
								<div className='border-b-8 border-solid pb-4 border-gray-100' />
								<div className='mx-4 mt-4'>
									<div className='text-gray-900 text-sm font-semibold'>Order Summary</div>
									<div className='mt-4'>
										{cart?.item?.map((item, index) => {
											return (
												<CartDetail
													productKey={`${item?.productID}_${item?.productName}_${index}`}
													id={item?.productID}
													image={item?.itemImage}
													note={item?.itemNotes}
													comboSet={item?.comboset}
													slug={item?.productID}
													rating={0}
													totalRating={0}
													name={item?.itemName}
													price={Number(item?.itemTotalPrice)}
													strikeOutPrice={Number(0)}
													productType={item?.productType}
													handleGetProductDetail={handleGetProductDetail}
													cart={cart}
													handleCart={handleCart}
													size='small'
												/>
											);
										})}
									</div>
								</div>
								<div className='border-b-1 border-solid pb-2 border-gray-200' />
								<div className='flex justify-between mx-4 my-4 items-center'>
									<div>
										<div className='text-sm text-gray-900 font-semibold'>Add More?</div>
										<div className='text-xs text-gray-500 pt-1 font-thin'>Need anything else? add other dishes</div>
									</div>
									<button
										className='bg-white border-solid border-1 border-rose-violet py-1.5 px-6 text-xs font-semibold text-rose-violet rounded-md'
										onClick={() => history.push(`/outlet/${cart?.store?.storeID}`)}>
										Add
									</button>
								</div>
								<div className='border-b-1 border-solid pb-2 border-gray-200' />
								<div
									className={`flex ${isLoadingVoucherList ? 'justify-center' : 'justify-between'} mx-4 my-4 items-center rounded-lg h-16 relative`}
									onClick={() => {
										if (isLoadingVoucherList) return;
										setIsShowVoucher(true);
									}}>
									{isLoadingVoucherList ? (
										<LoaderIcon />
									) : (
										<>
											<TicketDiscountIcon className='bg-white rounded-full z-10 bg-opacity-30 h-9 w-auto p-0.5 ml-4' />
											<div className='text-byzantium font-semibold text-xs max-w-56'>Add Voucher! Get an addtional discount up to 30k</div>
											<ChevronRightIcon className='h-5 w-5 text-byzantium z-10 bg-white rounded-full mr-4 p-1' strokeWidth={3} />
										</>
									)}
									<img src='./assets/img/bg-voucher.png' className='h-full w-full absolute rounded-lg z-0' />
								</div>
								<div className='mx-4 mt-4'>
									<div className='text-gray-900 text-sm font-semibold'>Payment Method</div>
									<div
										className='mt-2 border-solid border-1 border-gray-300 rounded-lg px-4 py-2 flex justify-between items-center h-14'
										onClick={() => setIsShowPaymentMethod(true)}>
										<div className='flex items-center'>
											{values?.paymentType.code === 'ULTRA-VOUCHER' ? (
												<UltraVoucherIcon />
											) : (
												<img alt={values?.paymentType?.alt ?? ''} src={values?.paymentType?.image_url} className='w-9 h-auto' />
											)}
											<div className='pl-2'>
												<div className='text-gray-700 text-xs font-thin'>{values?.paymentType?.name ?? ''}</div>
												{values?.paymentType.code === 'ULTRA-VOUCHER' && <div className='text-gray-900 text-sm font-semibold'>{formatRupiah(UVBalance?.value || 0)}</div>}
											</div>
										</div>
										<ChevronRightIcon className='h-4 w-4 text-gray-800' strokeWidth={3} />
									</div>
								</div>
								<div className='mx-4 mt-4 mb-28'>
									<div className='text-gray-900 text-sm font-semibold'>Payment Summary</div>
									<div className='mt-4 border-solid border-1 border-gray-300 rounded-lg px-4 py-2.5'>
										<div className={`flex justify-between items-center`}>
											<div className='text-gray-900 text-xs font-normal'>Subtotal</div>
											<div className='text-xs font-normal flex'>
												<div className='text-gray-400 font-thin line-through hidden'>Rp210.000</div>
												<div className='text-rose-violet pl-2'>{formatRupiah(paymentSummary?.subTotal || 0)}</div>
											</div>
										</div>
										<div className={`${!paymentSummary?.deliveryFee ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
											<div className='text-gray-900 text-xs font-normal'>Delivery Fee</div>
											<div className='text-xs font-normal flex'>
												<div className='text-gray-400 font-thin line-through hidden'>Rp16.000</div>
												<div className='text-rose-violet pl-2'>{formatRupiah(paymentSummary?.deliveryFee || 0)}</div>
											</div>
										</div>
										<div className={`${!paymentSummary?.platformFee ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
											<div className='text-gray-900 text-xs font-normal'>Platform Fee</div>
											<div className='text-xs font-normal flex'>
												<div className='text-gray-400 font-thin line-through hidden'>Rp16.000</div>
												<div className='text-rose-violet pl-2'>{formatRupiah(paymentSummary?.platformFee || 0)}</div>
											</div>
										</div>
										<div className={`${!paymentSummary?.packagingFee ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
											<div className='text-gray-900 text-xs font-normal'>Packaging Fee</div>
											<div className='text-xs font-normal flex'>
												<div className='text-gray-400 font-thin line-through hidden'>Rp16.000</div>
												<div className='text-rose-violet pl-2'>{formatRupiah(paymentSummary?.packagingFee || 0)}</div>
											</div>
										</div>
										<div className={`${!paymentSummary?.paymentFee ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
											<div className='text-gray-900 text-xs font-normal flex items-center'>
												Payment Fee <InformationCircleIcon className='h-4 w-4 ml-1 text-gray-400 hidden' onClick={() => console.log('click info')} />
											</div>
											<div className='text-xs font-normal flex'>
												<div className='text-gray-700 pl-2'>{formatRupiah(paymentSummary?.paymentFee || 0)}</div>
											</div>
										</div>
										<div className={`${!paymentSummary?.otherFee ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
											<div className='text-gray-900 text-xs font-normal flex items-center'>
												Other Fee(s) <InformationCircleIcon className='h-4 w-4 ml-1 text-gray-400' onClick={() => console.log('click info')} />
											</div>
											<div className='text-xs font-normal flex'>
												<div className='text-gray-700 pl-2'>{formatRupiah(paymentSummary?.otherFee || 0)}</div>
											</div>
										</div>
										<div className={`${!paymentSummary?.myVoucherApplied ? 'hidden' : 'flex'} justify-between items-center mt-2`}>
											<div className='text-gray-900 text-xs font-normal flex items-center'>
												<TicketDiscountIcon className='h-6 w-6 mr-1' />
												Voucher Applied{' '}
												<InformationCircleIcon className='h-4 w-4 ml-1 text-gray-400' onClick={() => console.log('myVoucherApplied', paymentSummary?.countMyVoucherApplied)} />
											</div>
											<div className='text-xs font-normal flex'>
												<div className='text-rose-violet pl-2'>-{formatRupiah(paymentSummary?.myVoucherApplied || 0)}</div>
											</div>
										</div>
										<div className={`${!paymentSummary?.voucherData?.totalB2BPrice ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
											<div className='text-gray-900 text-xs font-normal'>Voucher {cart?.store?.storeName}</div>
											<div className='text-xs font-normal flex'>
												<div className='text-gray-400 font-thin line-through hidden'>Rp50.000</div>
												<div className='text-gray-700 pl-2'>{formatRupiah(paymentSummary?.voucherData?.totalB2BPrice || 0)}</div>
											</div>
										</div>
										<div className='border-b-1 border-solid pb-2 border-gray-300' />
										<div className='flex justify-between items-center mt-2'>
											<div className='text-gray-900 text-sm font-extrabold items-center'>Total Payment</div>
											<div className='text-sm font-extrabold flex'>
												<div className='text-gray-900'>{formatRupiah(paymentSummary?.totalPayment || 0)}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{cart && !isShowDelivery && (
								<Footer position='fixed' id='cart'>
									<div className={`bg-white p-4 shadow-custom transition-transform duration-1000 ease-in-out ${cart ? 'translate-y-0 relative' : 'translate-y-full hidden'}`}>
										<div className='items-center hidden'>
											<DiscountIcon />
											<div className='pl-2 text-gray-900 text-xs'>Yay, you’re saving 10k. Add more and save up to 50k!</div>
										</div>
										<button
                      disabled={isLoadingCart || isLoadingDeliveryList || isLoadingVoucherList}
											className={`${
												isLoadingCart || isLoadingDeliveryList || isLoadingVoucherList ? 'bg-gray-300 text-white' : 'bg-byzantium text-white'
											} mt-2 w-full flex justify-between font-normal text-sm p-3 items-center border-0`}
											onClick={() => handleSubmit()}>
											<div>Pay and Order</div>
											<div>{formatRupiah(parseInt(paymentSummary?.totalPayment || 0))}</div>
										</button>
									</div>
								</Footer>
							)}
							{cartItem && cart && <ProductCart product={cartItem} cart={cart} handleCart={handleCart} setCartItem={setCartItem} />}
							{isShowDelivery && (
								<Footer position='fixed' id='delivery-list' onTouchOutside={() => setIsShowDelivery(false)}>
									<div
										className={`bg-white p-4 shadow-custom transition-transform duration-1000 ease-in-out rounded-t-xl ${
											isShowDelivery ? 'translate-y-0 relative' : 'translate-y-full hidden'
										}`}>
										<div className='flex justify-center'>
											<div className='bg-gray-300 h-1.5 w-10 rounded-full' />
										</div>
										<div className='flex justify-center py-4 text-base text-gray-900 font-semibold'>Delivery Service</div>
										{pathnersList?.map((pathner, index) => {
											return (
												<div
													key={`pathner_${index}`}
													className={`border-1 border-gray-300 border-solid rounded-md flex justify-between px-2 py-2.5 mt-2 items-center`}
													onClick={() => setDelivery(pathner)}>
													<div className=''>
														<div className={`text-sm capitalize text-gray-900 font-semibold`}>{`${pathner?.name} ${pathner?.serviceType}`}</div>
														<div className='text-xs text-gray-400 pt-1 font-thin'>Estimated arrival 30-40 min direct to you</div>
													</div>
													<div className='flex'>
														<div className='pl-2'>
															<input
																id='pathner'
																type='radio'
																name='pathner'
																value={pathner?.name}
																checked={delivery?.name === pathner?.name}
																onChange={() => null}
																className={`${delivery?.name === pathner?.name ? '!border-rose-violet' : '!border-gray-400'} after:!bg-rose-violet active:bg-white`}
															/>
														</div>
													</div>
												</div>
											);
										})}
										<button
											className='mt-10 w-full bg-byzantium text-white flex justify-center font-normal text-sm p-3 border-0 items-center'
											onClick={() => setIsShowDelivery(false)}>
											Got It
										</button>
									</div>
								</Footer>
							)}
							{isShowVoucher && (
								<Footer
									position='fixed'
									id='voucher-list'
									onTouchOutside={() => {
										setVoucherList((prev) => {
											return {
												...prev,
												more: false,
											};
										});
										setIsShowVoucher(false);
									}}>
									<div
										className={`bg-gray-50 p-4 shadow-custom transition-transform duration-1000 ease-in-out rounded-t-xl ${
											isShowVoucher ? 'translate-y-0 relative' : 'translate-y-full hidden'
										}`}>
										<div className='flex justify-center'>
											<div className='bg-gray-300 h-1.5 w-10 rounded-full' />
										</div>
										<div className='flex justify-start py-4 text-base text-gray-900 font-semibold'>Add Voucher</div>
										<div className='border-b-1 border-solid border-gray-200 mb-4' />
										<div className='h-10 w-full flex gap-2 items-center mb-4'>
											{voucherList?.tabs?.map((tab, index) => {
												return (
													<div
														key={`tab_${index}`}
														onClick={() => {
															setVoucherList((prev) => {
																return {
																	...prev,
																	active: tab,
																};
															});
														}}
														className={` border-1 border-solid  rounded-3xl px-4 py-1.5 text-xs font-normal  ${
															voucherList?.active === tab
																? 'bg-rose-violet bg-opacity-15 border-rose-violet border-opacity-0 text-rose-violet'
																: 'bg-gray-100 border-gray-100 text-gray-500'
														}`}>
														{tab === 'All' ? tab : formatRupiah(tab)}
													</div>
												);
											})}
										</div>
										<div className={`mt-4 ${voucherList?.more ? 'h-full max-h-[38rem] overflow-y-scroll scrollbar-hide bg-gray-50' : ''}`}>
											{voucherList?.more ? (
												<div className=''>
													<div className='text-base font-semibold text-gray-900'>My Voucher</div>
													{myVoucherFiltered?.map((voucher, index) => {
														return (
															<Voucher
																voucherKey={`my_voucher_all_${index}`}
																showLabelCount={false}
																type={'my_voucher'}
																data={voucher}
																handleUseVoucher={(data) => handleUpdateVoucherList(data.id, 'my_voucher', data.is_use ?? false)}
																isDisabled={paymentSummary?.isDisableVoucher}
															/>
														);
													})}
													<div className={`${voucherList?.b2b?.length ? 'mb-5' : 'hidden'}`}>
														<div className='text-base font-semibold text-gray-900 mt-4'>More Voucher from {cart?.store?.storeName ?? ''}</div>
														{b2bVoucherFiltered?.map((voucher, index) => {
															return (
																<Voucher
																	voucherKey={`b2b_voucher_all_${index}`}
																	showLabelCount={false}
																	type={'b2b_voucher'}
																	data={voucher}
																	handleUseVoucher={(data) => handleUpdateVoucherList(data.code, 'b2b_voucher', data.is_use ?? false)}
																	isDisabled={paymentSummary?.isDisableVoucher}
																/>
															);
														})}
													</div>
												</div>
											) : (
												<div className=''>
													<div className='text-base font-semibold text-gray-900'>My Voucher</div>
													{totalVoucherFiltered?.map((voucher, index) => {
														return (
															<Voucher
																voucherKey={`my_voucher_${index}`}
																showLabelCount={true}
																type={'my_voucher'}
																data={voucher}
																handleUseVoucher={(data) => handleUpdateVoucherList(data.id, 'my_voucher', data.is_use ?? false)}
																isDisabled={paymentSummary?.isDisableVoucher}
															/>
														);
													})}
												</div>
											)}
										</div>
										<div
											className={`${voucherList?.more ? 'hidden' : 'flex'} mt-4 justify-center items-center text-green-600 text-sm font-semibold w-full`}
											onClick={() => {
												console.log('onClick more voucher');
												setVoucherList((prev) => {
													return {
														...prev,
														more: !voucherList?.more ?? false,
													};
												});
											}}>
											See More
											<ChevronDownIcon class='h-5 w-5 text-green-600' strokeWidth={3} />
										</div>
										<div className='border-b-8 border-solid pb-5 border-gray-200 -mx-4 w-screen' />
										<button
											className='mt-10 w-full bg-byzantium text-white flex justify-center font-normal text-sm p-3 border-0 items-center'
											onClick={() => {
												setVoucherList((prev) => {
													return {
														...prev,
														more: false,
													};
												});
												setIsShowVoucher(false);
											}}>
											Submit
										</button>
									</div>
								</Footer>
							)}
							{isShowPaymentMethod && (
								<Footer position='fixed' id='payment-list' onTouchOutside={() => setIsShowPaymentMethod(false)}>
									<div
										className={`bg-white p-4 shadow-custom transition-transform duration-1000 ease-in-out rounded-t-xl ${
											isShowPaymentMethod ? 'translate-y-0 relative' : 'translate-y-full hidden'
										}`}>
										<div className='flex justify-center'>
											<div className='bg-gray-300 h-1.5 w-10 rounded-full' />
										</div>
										<div className='flex justify-center py-4 text-base text-gray-900 font-semibold'>Select Payment Method</div>
										{paymentMethodList?.map((method, index) => {
											return (
												<div className='w-full'>
													<div className='flex justify-start py-4 text-sm text-gray-800 font-semibold capitalize'>{method?.category ?? ''}</div>
													{method?.payment_method?.map((payment, index) => {
														return (
															<div
																key={`payment_${index}`}
																className={`border-1 border-gray-300 border-solid rounded-md flex justify-between px-2 py-2.5 mt-2 items-center h-14`}
																onClick={() => {
																	console.log('oncliek payment type', payment);
																	setValues((prev) => {
																		return {
																			...prev,
																			paymentType: payment,
																		};
																	});
																}}>
																<div className='flex items-center'>
																	{payment.code === 'ULTRA-VOUCHER' ? (
																		<UltraVoucherIcon />
																	) : (
																		<img alt={payment?.alt ?? ''} src={payment?.image_url} className='w-9 h-auto' />
																	)}
																	<div className='pl-2'>
																		<div className={`text-xs capitalize text-gray-900 font-semibold`}>{`${payment?.name}`}</div>
																		{payment.code === UVBalance?.code && (
																			<div className='text-xs text-gray-700 pt-1 font-thin'>Balance {formatRupiah(UVBalance?.value || 0)}</div>
																		)}
																	</div>
																</div>
																<div className='flex'>
																	<div className='pl-2'>
																		<input
																			id='payment'
																			type='radio'
																			name='payment'
																			value={payment?.code}
																			checked={values?.paymentType?.code === payment?.code}
																			onChange={() => null}
																			className={`${
																				values?.paymentType?.code === payment?.code ? '!border-rose-violet' : '!border-gray-400'
																			} after:!bg-rose-violet active:bg-white`}
																		/>
																	</div>
																</div>
															</div>
														);
													})}
												</div>
											);
										})}
										<button
											className='mt-10 w-full bg-byzantium text-white flex justify-center font-normal text-sm p-3 border-0 items-center'
											onClick={() => setIsShowPaymentMethod(false)}>
											Choose Payment
										</button>
									</div>
								</Footer>
							)}
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Summary;
