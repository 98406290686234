import React, { Component } from "react";

import hoore from "../../common/images/hoorey.svg";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="main-div success-done">
        <div className="container">
          <div className="textcenter" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={hoore} className="tick-vector" />
            <h1>Payment Completed</h1>
            <p>
              Congratulations, everything has been paid off. Please continue to
              see transaction details
            </p>
            <a href="#" className="button">
              View Transaction Details
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
