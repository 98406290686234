import React, { useMemo, useState } from 'react';
import { formatRupiah, isEmptyData, removeHTML } from '../../components/Helpers/SettingHelper';
import '../../tailwind.css';
import { PlusIcon, MinusIcon, InformationCircleIcon, ClockIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import FoodImg from '../../common/images/food.png';
import { formatDate } from '../../utils/helper.js';

const Voucher = ({ voucherKey = '', data = {}, handleUseVoucher = (data) => {}, type = 'my_voucher', showLabelCount = false, isDisabled = false }) => {
	const [imageLoadStatus, setImageLoadStatus] = useState('loaded');

	return (
		<div key={`${type}_${voucherKey}`} className='h-52 w-full relative mt-4'>
			{showLabelCount && (
				<div className='z-20 relative top-1 -left-2'>
					<img src='./assets/img/label-voucher.png' className='h-auto w-20 object-fill absolute shadow-custom bg-transparent rounded-lg' />
					<div className='text-white text-xs z-20 absolute h-7 items-center flex pl-2'>{data?.total_voucher || 0} voucher</div>
				</div>
			)}
			<img src='./assets/img/bg-voucher-list.png' className='h-full w-full object-fill absolute z-0 shadow-custom bg-gray-50 rounded-lg top-0' />
			<div className='relative z-10 p-4 flex flex-col justify-between'>
				<div className='flex'>
					{imageLoadStatus === 'loaded' ? (
						<img
							alt={data?.name}
							src={data?.image?.url || data?.image}
							onLoad={() => {
								setImageLoadStatus('loaded');
							}}
							onError={() => {
								setImageLoadStatus('error');
							}}
							className='h-20 w-20 rounded-xl object-cover object-center border-1 border-solid border-gray-100 bg-gray-200'
						/>
					) : (
						<img alt={data?.name} src={FoodImg} className='h-20 w-20 rounded-xl object-cover object-center border-1 border-solid border-gray-100' />
					)}
					<div className={`pl-3 w-full h-full ${!isEmptyData(data?.expired_date) ? 'mb-16' : 'mb-20'}`}>
						<div className='flex justify-between items-center'>
							<div className='text-gray-500 text-sm font-semibold max-w-60 truncate'>{data?.name}</div>
							{!isEmptyData(data?.expired_date) && <InformationCircleIcon class='h-5 w-5 text-gray-400' />}
						</div>
						<div className='flex mt-1 items-center'>
							<div className='text-gray-500 text-xs font-normal'>Total Nominal:</div>
							<div className='pl-1 text-green-600 text-base font-semibold'>{formatRupiah(data?.nominal_voucher || data?.nominal || 0)}</div>
						</div>
						<div className={`${isEmptyData(data?.expired_date) ? 'hidden' : 'flex'} mt-1 items-center`}>
							<ClockIcon class='h-5 w-5 text-red-500' />
							<div className='text-gray-500 text-xs font-semibold pl-1'>Exp Date :</div>
							<div className='text-gray-500 text-xs font-semibold pl-1'>{formatDate(data?.expired_date, 'DD MMM YYYY')}</div>
						</div>
					</div>
				</div>
				<div className={`flex items-center ${data.is_use ? 'justify-between' : 'justify-end'} w-full`}>
					<div className={data.is_use ? 'flex items-center' : 'hidden'}>
						<CheckCircleIcon class='h-5 w-5 text-green-600' />
						<div className='pl-1 text-gray-600 text-sm font-semibold'>Applied</div>
					</div>
					<button
						disabled={data.is_use ? false : isDisabled ? isDisabled : false}
						onClick={() => handleUseVoucher(data)}
						className={`py-2 px-8 ${data.is_use ? 'text-red-500 border-red-500' : isDisabled ? 'text-gray-300 border-gray-300' : 'text-green-600'} font-semibold text-sm`}>
						{data.is_use ? 'Remove' : 'Use'}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Voucher;
