import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";

import barrow from "../../common/images/back-arrow.svg";
import pyvector from "../../common/images/payment-vector.svg";
import cpyicon from "../../common/images/copy-icon.svg";
import bcaa from "../../common/images/bca.png";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="main-div">
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <a href="#" className="arrow-back">
                <img src={barrow} />
              </a>
            </div>
            <div className="ha-middle">Payment Details</div>
            <div className="ha-rhs ha-rhs-link"></div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            <div className="virtual-acc textcenter">
              <img src={pyvector} className="pyt-vector" />
              <h1>Virtual Account</h1>
              <p>
                Please make a transaction by copying the VA
                <br /> number and the price to be paid
              </p>
            </div>
            <div className="paym-deadline">
              <div className="pd-img">
                <img src={bcaa} />
              </div>
              <div className="pd-text">
                <p>Payment Deadline</p>
                <strong>Monday, 27 Dec 2021 17:16 WIB</strong>
                <span>23:59:30</span>
              </div>
            </div>
            <div className="paym-list">
              <ul>
                <li>
                  <span>VA Number</span>
                  <strong>
                    8023469182658916
                    <a href="#">
                      <img src={cpyicon} />
                    </a>{" "}
                  </strong>
                </li>
                <li>
                  <span>Total Payment</span>
                  <strong>
                    Rp. 180.000
                    <a href="#">
                      <img src={cpyicon} />
                    </a>{" "}
                  </strong>
                </li>
              </ul>
              <a href="#" className="aldy-button button">
                Already Paid
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
