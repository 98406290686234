import moment from 'moment-timezone';
moment.locale('id');

export const formatDate = (value, format = 'DD-MM-YYYY H:m', isUTC = false, timeZone = 'Asia/Jakarta') => {
	// Jika tidak ada value, kembalikan string kosong
	if (!value) return '';

	// Jika value adalah string dan tidak sudah dalam format UTC, tambahkan 'Z'
	if (typeof value === 'string' && !value.endsWith('Z')) {
		// Ubah input menjadi format UTC
		value += 'Z';
	}

	// Buat objek Date jika value adalah string, jika tidak gunakan value langsung
	const date = new Date(value);

	// Periksa apakah date valid
	if (!moment(date).isValid()) {
		return ''; // Kembalikan string kosong jika tanggal tidak valid
	}

	// Jika isUTC adalah true, konversi dari UTC ke timezone Jakarta
	if (isUTC) {
		return moment.utc(date).tz(timeZone).format(format);
	} else {
		// Jika tidak, format tanggal langsung dengan timezone yang diberikan
		return moment(date).tz(timeZone).format(format);
	}
};
