import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import cookie from "react-cookies";
import ContentLoader from "react-content-loader";
import { apiUrl, unquieID } from "../Settings/Config";
import { showLoader, hideLoader, encodeValue } from "../Settings/SettingHelper";
import Cart from "../Cart/Cart";
import cart from "../../common/images/cart.svg";
import barrow from "../../common/images/back-arrow.svg";
import food from "../../common/images/food.png";
import favheart from "../../common/images/favorite-heart.svg";
import favclip from "../../common/images/no-favourite-clip.svg";
import $ from "jquery";
import { getCookie } from "../Helpers/SettingHelper";

var qs = require("qs");
class Favouritelist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tagList: [],
      storeList: [],
      storeDisplay: "",
      loading: true,
      cartdetails: [],
    };
  }
  componentDidMount() {
    console.log('componentDidMount');
    this.loadStore();
  }
  componentWillReceiveProps(PropsDt) {
    console.log('componentWillReceiveProps');
    if (this.state.cartdetails !== PropsDt.cartdetails) {
      this.setState({ cartdetails: PropsDt.cartdetails });
    }
  }
  loadTag() {
    console.log('loadTag');
    axios.get(apiUrl + "store/tagList?unquieid=" + unquieID).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ tagList: res.data.result }, function () {
          this.loadStore();
        });
      }
    });
  }
  loadStore() {
    console.log('loadStore');
    const latitude = getCookie("latitude");
    const longitude = getCookie("longitude");

    axios
      .get(
        apiUrl +
          "store/listStore?unquieid=" +
          unquieID +
          "&latitude=" +
          latitude +
          "&longitude=" +
          longitude +
          "&favourite=Yes&customerID=" +
          cookie.load("customerID")
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ storeList: res.data.result }, function () {
            this.displayStore("");
          });
        } else {
          this.setState({ storeList: [] }, function () {
            this.displayStore("");
          });
        }
      });
  }
  displayStore(storeID) {
    console.log('displayStore');
    var storeDisplay = "";
    if (this.state.storeList.length > 0) {
      storeDisplay = this.state.storeList.map((item, index) => {
        return (
          <li key={index} id={`store-${item.storeID}`}>
            <a href="#" className="ot-parent">
              <div style={{height: '80px', width: '80px'}}>
                <img
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                  src={
                    item.storeImage !== "" && item.storeImage !== null
                      ? item.storeImage
                      : food
                  }
                  alt={item.storeName}
                />
              </div>
              <div className="ot-info">
                <h3 className="ot-title">{item.storeName}</h3>
                <div className="km-rating">
                  <strong>
                    {" "}
                    {item.distance !== ""
                      ? parseFloat(item.distance).toFixed("2")
                      : "0"}{" "}
                    km
                  </strong>
                  <span>
                    {item.rating} ({item.totalRating})
                  </span>
                </div>
                <div className="op-time">{item.storeTimeInfo}</div>
                {item.tagID !== "" &&
                  item.tagID !== null &&
                  this.state.tagList.length > 0 && (
                    <div className="ot-keyword">
                      {this.loadStoreTag(item.tagID)}
                    </div>
                  )}
                {item.offerInfo !== "" && item.offerInfo !== null && (
                  <div className="ot-offers">{item.offerInfo}</div>
                )}
              </div>
            </a>
            <a
              href={void 0}
              onClick={this.removeFavourite.bind(this, item.storeID)}
              className="add-fav"
            >
              {" "}
              <img src={favheart} alt="Heart" />
            </a>
          </li>
        );
      });
    }
    this.setState({ storeDisplay: storeDisplay, loading: false }, function () {
      hideLoader("overlay-loader", "class");
    });
  }
  removeFavourite(storeID) {
    console.log('removeFavourite');
    var postObject = {
      unquieid: unquieID,
      shopID: encodeValue(storeID),
      customerID: cookie.load("customerID"),
    };
    showLoader("store-" + storeID);
    axios
      .post(apiUrl + "store/removeFavourite", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "success") {
          this.loadStore(storeID);
        } else if (res.data.status === "error") {
          hideLoader("store-" + storeID);
        }

        return false;
      });
  }
  render() {
    return (
      <div className="main-div">
        <Cart />
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <Link to={"/"} className="arrow-back">
                <img src={barrow} />
              </Link>
            </div>
            <div className="ha-middle">Favorite</div>
            <div className="ha-rhs">
              <ul>
                <li>
                  <Link to={"/cart"}>
                    <img src={cart} />
                    {Object.keys(this.state.cartdetails).length > 0 && (
                      <span className="wish-count">
                        {this.state.cartdetails.totalItem}+
                      </span>
                    )}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="content-body rel">
          <div className="container">
            {this.state.loading === false &&
              this.state.storeList.length === 0 && (
                <div className="no-favtxt">
                  <img src={favclip} />
                  <h2>There is no favorite restaurant</h2>
                  <p>Tap the heart icon on your favorite restaurant</p>
                  <Link to={"/restaurants"} className="button">
                    Find a restaurant
                  </Link>
                </div>
              )}

            <div className="outlet-listing fav-listing">
              <div className="outlet-main-list">
                <ul>
                  {this.state.loading === true
                    ? Array(1, 2, 3).map((item) => {
                        return (
                          <li key={item}>
                            <ContentLoader viewBox="0 0 380 70">
                              <rect
                                x="0"
                                y="0"
                                rx="5"
                                ry="5"
                                width="70"
                                height="70"
                              />
                              <rect
                                x="80"
                                y="17"
                                rx="4"
                                ry="4"
                                width="300"
                                height="13"
                              />
                              <rect
                                x="80"
                                y="40"
                                rx="3"
                                ry="3"
                                width="250"
                                height="10"
                              />
                            </ContentLoader>
                          </li>
                        );
                      })
                    : this.state.storeDisplay}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var cartdetailsArr = Array();
  if (Object.keys(state.cartdetails).length > 0) {
    if (state.cartdetails[0].status === "ok") {
      cartdetailsArr = state.cartdetails[0].result;
    }
  }

  return {
    cartdetails: cartdetailsArr,
  };
};

export default connect(mapStateTopProps)(withRouter(Favouritelist));
