import '../../tailwind.css';
import { LoaderIcon } from '../../utils/svg';

const Loader = ({ overlay = false }) => {
	return (
		<div className={`h-screen w-full flex justify-center items-center touch-none ${overlay && 'bg-gray-400 opacity-50 fixed z-50'}`}>
			<div className='flex h-screen w-full max-w-md justify-center items-center'>
				<div className='h-fit w-fit'>
					<LoaderIcon />
					<span className='sr-only'>Loading...</span>
				</div>
			</div>
		</div>
	);
};

export default Loader;
